import React, { useState, useEffect, useContext } from 'react';
import NavSide from '../Components/NavSide';
import NavTop from '../Components/NavTop';
import filterIcon from './../images/filter.svg';
import exportIcon from './../images/export.svg';
import addNew from './../images/add-new.svg';
import sortArrow from './../images/sort-arrow.svg';
import sortElipses from './../images/elipses.svg';
import newUsersIcon from './../images/new-users-icons.png';
import userIcon from './../images/portrait_placeholder.png';
import userEmail from './../images/email-user.svg';
import userCal from './../images/cal-user.svg';
import { gql, useQuery, useMutation } from '@apollo/client';
import rollbackImage from './../images/rollback.svg';
import { NotifContext } from '../Context/notifContext';
import { AuthContext } from '../Context/authContext';
import { useNavigate } from 'react-router';

const USER_QUERY = gql`
  query GetUsers {
    users {
      name
      id
      email
      password
      phone
      lastLogin
      type
      isActive
      token
      batchNumber
      resNotUser
      refNotUser
      verNotUser
    }
  }
`;

const USERS_NOT_SUBSCRIPTION = gql`
  subscription Subscription {
    userNot {
      id
      type
    }
  }
`;

const REGISTER_USER = gql`
  mutation Mutation($registerInput: RegisterInput) {
    registerUser(registerInput: $registerInput) {
      id
      email
      lastLogin
      type
      token
    }
  }
`;

export default function Users() {
  const { user } = useContext(AuthContext);
  let navigate = useNavigate();

  if (user.type !== 'admin') {
    navigate('/dashboard');
  }

  const [selected, setSelected] = useState([]);
  const [current, setCurrent] = useState(true);
  const [filtered, setFiltered] = useState(false);
  const [filter, setFilter] = useState(0);
  const [sort, setSort] = useState(3);
  const [search, setSearch] = useState('');
  const [popup, setPopup] = useState(false);
  const { handleNotificationSuccess } = useContext(NotifContext);

  const initialState = {
    name: '',
    email: '',
    password: '',
    phone: '',
  };

  const [input, setInput] = useState(initialState);
  const [newInput, setNewInput] = useState({});

  const { subscribeToMore, loading, error, data, refetch } =
    useQuery(USER_QUERY);

  // console.log('DATA', data);

  subscribeToMore({
    document: USERS_NOT_SUBSCRIPTION,
    updateQuery: () => {
      // console.log("WE MADE IT HERE")
      refetch();
    },
  });

  function handleRemoveSelected(x) {
    const temp = [];
    for (let i = 0; i < selected.length; i++) {
      if (selected[i] !== x) {
        temp.push(selected[i]);
      }
    }
    setSelected(temp);
  }

  function handleAddSelected(x) {
    const temp = [];
    for (let i = 0; i < selected.length; i++) {
      temp.push(selected[i]);
    }
    temp.push(x);
    setSelected(temp);
  }

  function handleSearch(x, y, z, sort) {
    let availableUsers = [];
    y.forEach((user) => {
      if (z === 0) {
        availableUsers.push(user);
      } else if (z === 1) {
        if (user.refNotUser === 1) {
          availableUsers.push(user);
        }
        if (user.resNotUser === 1) {
          availableUsers.push(user);
        }
        if (user.verNotUser === 1) {
          availableUsers.push(user);
        }
      } else if (z === 2) {
        if (user.refNotUser === 1) {
          availableUsers.push(user);
        }
      } else if (z === 3) {
        if (user.resNotUser === 1) {
          availableUsers.push(user);
        }
      } else if (z === 4) {
        if (user.verNotUser === 1) {
          availableUsers.push(user);
        }
      }
    });
    if (x !== '') {
      availableUsers = availableUsers.filter((user) =>
        user.name.toLowerCase().includes(x.toLowerCase())
      );
    }
    if (sort !== 0) {
      if (sort === 3) {
        availableUsers = availableUsers.sort(function (a, b) {
          return new Date(b.lastLogin) - new Date(a.lastLogin);
        });
      } else if (sort === 4) {
        availableUsers = availableUsers.sort(function (a, b) {
          return new Date(a.lastLogin) - new Date(b.lastLogin);
        });
      }
    }

    return availableUsers;
  }

  const [registerUser, { loading: NewLoading }] = useMutation(REGISTER_USER, {
    onCompleted: () => {
      refetch();
      setPopup(false);
      handleNotificationSuccess(true);
    },
    onError: () => handleNotificationSuccess(false),
    variables: { registerInput: newInput },
  });

  const handleOnChange = (event) => {
    const newState = { ...input, [event.target.name]: event.target.value };
    setInput(newState);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setNewInput({
      name: input.name,
      password: input.password,
      email: input.email,
      phone: input.phone,
      type: 'user',
    });
    registerUser();
  };

  // useEffect(() => {
  //   if(newInput !== undefined){
  //     if(newInput !== {}){

  //     }
  //   }

  // }, [newInput])

  const handleCancel = () => {
    setInput(initialState);
    setPopup(false);
  };

  if (loading) {
    return (
      <>
        <NavSide current="user" />
        <div className="loading__wrap__full">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="wrap__dark">
        {popup === false ? (
          ''
        ) : (
          <div className="popup__main">
            <div className="edit__user__wrap">
              <div className="edit__user">
                <form onSubmit={handleFormSubmit}>
                  <div className="edit__user__top">
                    <h3>
                      Create a <span>new</span> user
                    </h3>
                    <div className="login__item field">
                      <p>Name</p>
                      <input
                        type="text"
                        className="input"
                        name="name"
                        onChange={handleOnChange}
                        placeholder="User's name"
                        value={input.name}
                        required
                      />
                    </div>
                    <div className="login__item field">
                      <p>Email</p>
                      <input
                        type="email"
                        className="input"
                        name="email"
                        onChange={handleOnChange}
                        placeholder="Their Email address"
                        value={input.email}
                        required
                      />
                    </div>
                    <div className="login__item field">
                      <p>Password</p>
                      <input
                        type="text"
                        className="input"
                        name="password"
                        onChange={handleOnChange}
                        placeholder="User's password"
                        value={input.password}
                        required
                      />
                    </div>
                    <div className="login__item field">
                      <p>Phone</p>
                      <input
                        type="text"
                        className="input"
                        name="phone"
                        onChange={handleOnChange}
                        placeholder="User's phone number"
                        value={input.phone}
                        required
                      />
                    </div>
                  </div>
                  <div className="edit__user__bottom">
                    <button className="button" type="submit">
                      <img
                        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNLjAwMiAyMGg2LjAwMWMtLjAyOC02LjU0MiAyLjk5NS0zLjY5NyAyLjk5NS04LjkwMSAwLTIuMDA5LTEuMzExLTMuMDk5LTIuOTk4LTMuMDk5LTIuNDkyIDAtNC4yMjYgMi4zODMtMS44NjYgNi44MzkuNzc1IDEuNDY0LS44MjUgMS44MTItMi41NDUgMi4yMDktMS40OS4zNDQtMS41ODkgMS4wNzItMS41ODkgMi4zMzNsLjAwMi42MTl6bTIwLjQ5OC03Yy0xLjkzMiAwLTMuNSAxLjU2Ny0zLjUgMy41czEuNTY4IDMuNSAzLjUgMy41IDMuNS0xLjU2NyAzLjUtMy41LTEuNTY4LTMuNS0zLjUtMy41em0xLjUgNGgtMXYxaC0xdi0xaC0xdi0xaDF2LTFoMXYxaDF2MXptLTQuODE0IDNoLTkuMTgzbC0uMDAzLS44MjljMC0xLjY3OS4xMzMtMi42NDkgMi4xMTgtMy4xMDcgMi4yNDMtLjUxOCA0LjQ1OC0uOTgxIDMuMzk0LTIuOTQ1LTMuMTU2LTUuODItLjkwMS05LjExOSAyLjQ4OC05LjExOSA0LjA2IDAgNC44NTcgNC4xMTkgMy4wODUgNy45MDMtMS45NzIuNjA5LTMuNDE5IDIuNDI4LTMuNDE5IDQuNTk3IDAgMS4zOC41ODkgMi42MTkgMS41MiAzLjV6Ii8+PC9zdmc+"
                        alt=""
                      />{' '}
                      Create User
                    </button>
                    <button
                      className="button"
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      <img src={rollbackImage} alt="" /> Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        <NavTop
          current="pat"
          search={search}
          setSearch={setSearch}
          searchNumber={handleSearch(search, data.users, filter).length}
        />
        <NavSide current="user" />
        <div className="main__section main__list">
          <div className="list__container">
            <div className="title__list">
              <h1>User Management</h1>
              <div className="title__right">
                <button
                  className={filtered === false ? 'button' : 'button __active'}
                  onClick={
                    filtered === false
                      ? () => setFiltered(true)
                      : () => setFiltered(false)
                  }
                >
                  <img src={filterIcon} alt="" />
                  Filter
                </button>
                <button className="button __alt">
                  <img src={exportIcon} alt="" />
                  Export
                </button>
                <div
                  className={
                    filtered === false
                      ? 'title__details__alt'
                      : 'title__details__alt __active'
                  }
                >
                  <p>
                    Select an option to <span>Sort with</span>:
                  </p>
                  <button
                    className={filter !== 0 ? 'button' : 'button __active'}
                    onClick={() => setFilter(0)}
                  >
                    All Users
                  </button>
                  <button
                    className={filter !== 1 ? 'button' : 'button __active'}
                    onClick={() => setFilter(1)}
                  >
                    All Notifications
                  </button>
                  <button
                    className={filter !== 2 ? 'button' : 'button __active'}
                    onClick={() => setFilter(2)}
                  >
                    Reference Notifications
                  </button>
                  <button
                    className={filter !== 3 ? 'button' : 'button __active'}
                    onClick={() => setFilter(3)}
                  >
                    Resume Notifications
                  </button>
                  <button
                    className={filter !== 4 ? 'button' : 'button __active'}
                    onClick={() => setFilter(4)}
                  >
                    Verification Notifications
                  </button>
                </div>
              </div>
            </div>

            <div className="user__list">
              <div className="user__cont">
                <div className="columns__wrapper">
                  <button
                    className={
                      current === true
                        ? 'button __select __alt'
                        : 'button __select __alt __active'
                    }
                    onClick={
                      current === true
                        ? () => (
                            setCurrent(false),
                            setSelected([
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                              16, 17, 18, 19, 20,
                            ])
                          )
                        : () => (setCurrent(true), setSelected([]))
                    }
                  ></button>

                  <div className="columns is-vcentered is-gapless">
                    <div className="column is-3">
                      <button className="button __sort">
                        User's name{' '}
                        {/* <img src={sortArrow} alt="" className="__sort" /> */}
                      </button>
                    </div>
                    <div className="column is-2">
                      <button className="button __sort">
                        Batch No.{' '}
                        {/* <img src={sortArrow} alt="" className="__sort" /> */}
                      </button>
                    </div>
                    <div className="column is-3">
                      <button className="button __sort">
                        Email
                        {/* <img src={sortArrow} alt="" className="__sort" /> */}
                      </button>
                    </div>
                    <div className="column is-3">
                      <button
                        className="button __sort"
                        onClick={
                          sort === 3 ? () => setSort(4) : () => setSort(3)
                        }
                        style={{ textDecoration: 'Underline' }}
                      >
                        Latest activity{' '}
                        <img
                          src={sortArrow}
                          alt=""
                          className={sort === 4 ? '__invert' : ''}
                        />
                      </button>
                    </div>
                    <div className="column is-1">
                      <button className="button __sort">
                        Status
                        {/* <img src={sortArrow} alt="" className="__sort" /> */}
                      </button>
                    </div>
                  </div>

                  <button className="button __elipses __alt">
                    <img src={sortElipses} alt="" />
                  </button>
                </div>
              </div>

              {loading === true ? (
                <div className="loading__wrap">
                  <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              ) : error === true ? (
                <div className="error__data__wrap">
                  <p>An error has occured</p>
                </div>
              ) : handleSearch(search, data.users, filter, sort).length ? (
                handleSearch(search, data.users, filter, sort).map(
                  (user, index) => (
                    <div className="user__user" key={user.id}>
                      <div className="columns__wrapper">
                        {user.refNotUser + user.resNotUser + user.verNotUser >
                        0 ? (
                          <div className="has__not">
                            {user.refNotUser +
                              user.resNotUser +
                              user.verNotUser}
                          </div>
                        ) : (
                          ''
                        )}
                        <button
                          className={
                            selected.includes(index + 1)
                              ? 'button __select __active'
                              : 'button __select'
                          }
                          onClick={
                            selected.includes(index + 1)
                              ? () => handleRemoveSelected(index + 1)
                              : () => handleAddSelected(index + 1)
                          }
                        ></button>
                        <div className="columns is-vcentered is-gapless">
                          <div className="column is-3">
                            {user.type !== 'admin' ? (
                              <a
                                href={`/users/${user.id}?current=user`}
                                className="__item"
                              >
                                <img src={userIcon} alt="" />
                                {user.name}
                              </a>
                            ) : (
                              <a
                                className="__item"
                                style={{ cursor: 'not-allowed' }}
                              >
                                <img src={userIcon} alt="" />
                                {user.name}
                              </a>
                            )}
                          </div>
                          <div className="column is-2">
                            <p>#{user.batchNumber}</p>
                          </div>
                          <div className="column is-3">
                            <div className="__image">
                              <img src={userEmail} alt="" />
                              <p>{user.email}</p>
                            </div>
                          </div>
                          <div className="column is-3">
                            <div className="__image">
                              <img src={userCal} alt="" />
                              <p>{user.lastLogin}</p>
                            </div>
                          </div>
                          <div className="column is-1">
                            <p className="__status">active</p>
                          </div>
                        </div>
                        {user.type !== 'admin' ? (
                          <a href={`/users/${user.id}`}>
                            <button className="button __elipses">
                              <img src={sortElipses} alt="" />
                            </button>
                          </a>
                        ) : (
                          <a style={{ cursor: 'not-allowed' }}>
                            <button className="button __elipses" disabled>
                              <img src={sortElipses} alt="" />
                            </button>
                          </a>
                        )}
                      </div>
                    </div>
                  )
                )
              ) : (
                // <div className="error__data__wrap">
                //   <p>No users found</p>
                // </div>
                ''
              )}
            </div>

            {/* <div className="main__add">
              <img src={newUsersIcon} alt="" className="add__image" />
              <div className="main__text">
                <h5>{data.users.length} total users</h5>
                <h6>0 inactive users</h6>
              </div>
              <button className="button" onClick={() => setPopup(true)}>
                <img src={addNew} alt="" className="butt__img" />
                Add new User
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
