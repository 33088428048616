import React, { useContext, useState, useEffect, useRef } from 'react';
import sendIcon from './../images/icon-send.svg';
import { gql, useQuery, useMutation } from '@apollo/client';
import userIcon from './../images/portrait_placeholder.png';
import Editor from 'react-medium-editor';

require('medium-editor/dist/css/medium-editor.css');
require('medium-editor/dist/css/themes/default.css');

const MESSAGE_QUERY = gql`
  query MessagesChat($chatId: String!) {
    messagesChat(chatId: $chatId) {
      id
      date
      userId
      userName
      chatId
      content
    }
  }
`;

const MESSAGE_SUBSCRIPTION = gql`
  subscription Subscription($chatId: String!) {
    messagesChat(chatId: $chatId) {
      id
      date
      userId
      userName
      chatId
      content
    }
  }
`;

const MESSAGE_MUTATION = gql`
  mutation PostMessage($messageInput: messageInput) {
    postMessage(messageInput: $messageInput)
  }
`;

export default function Messenger(props) {
  const { userId, chatId, userName } = props;
  const bottomRef = useRef(null);

  const messageInitial = {
    userId: userId,
    userName: userName,
    chatId: chatId,
    content: '',
  };

  const [input, setInput] = useState(messageInitial);

  const { subscribeToMore, loading, error, data } = useQuery(MESSAGE_QUERY, {
    variables: { chatId },
  });

  subscribeToMore({
    document: MESSAGE_SUBSCRIPTION,
    variables: { chatId },
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const newFeedItem = subscriptionData.data.messagesChat;

      return Object.assign({}, prev, {
        messagesChat: { ...prev.messagesChat, newFeedItem },
      });
    },
  });

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [data]);

  const [sendMessage, { loading: newLoading, error: newError, data: newData }] =
    useMutation(MESSAGE_MUTATION, {
      update(proxy, { data: newData }) {
        const newState = { ...input, ['content']: '' };
        setInput(newState);
      },
      onError({ graphQLErrors }) {
        // console.log("ERROR")
      },
      variables: { messageInput: input },
    });
  console.log('ERROR', newError);

  function createLumps(messages) {
    const lumpsTemp = [];

    for (let i = 0; i < messages.length; i++) {
      if (lumpsTemp.length === 0) {
        const date = new Date(messages[i].date);
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        const currentDate = mm + '/' + dd + '/' + yyyy;
        const hourCurrent =
          date.getHours() > 12
            ? (date.getHours() - 12).toString()
            : date.getHours().toString();
        const minuteCurrent = ('0' + date.getMinutes()).slice(-2);
        const designation = date.getHours() > 12 ? 'pm' : 'am';
        const timeCurrent = hourCurrent + ':' + minuteCurrent + designation;

        lumpsTemp.push([
          currentDate,
          [
            [
              {
                id: messages[i].id,
                date: timeCurrent,
                userId: messages[i].userId,
                userName: messages[i].userName,
                content: messages[i].content,
              },
            ],
          ],
        ]);
      } else {
        const date = new Date(messages[i].date);
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        const currentDate = mm + '/' + dd + '/' + yyyy;
        const hourCurrent =
          date.getHours() > 12
            ? (date.getHours() - 12).toString()
            : date.getHours().toString();
        const minuteCurrent = ('0' + date.getMinutes()).slice(-2);
        const designation = date.getHours() > 12 ? 'pm' : 'am';
        const timeCurrent = hourCurrent + ':' + minuteCurrent + designation;

        if (currentDate === lumpsTemp[lumpsTemp.length - 1][0]) {
          if (
            lumpsTemp[lumpsTemp.length - 1][1][
              lumpsTemp[lumpsTemp.length - 1][1].length - 1
            ][
              lumpsTemp[lumpsTemp.length - 1][1][
                lumpsTemp[lumpsTemp.length - 1][1].length - 1
              ].length - 1
            ].userId === messages[i].userId
          ) {
            lumpsTemp[lumpsTemp.length - 1][1][
              lumpsTemp[lumpsTemp.length - 1][1].length - 1
            ].push({
              id: messages[i].id,
              date: timeCurrent,
              userId: messages[i].userId,
              userName: messages[i].userName,
              content: messages[i].content,
            });
          } else {
            lumpsTemp[lumpsTemp.length - 1][1].push([
              {
                id: messages[i].id,
                date: timeCurrent,
                userId: messages[i].userId,
                userName: messages[i].userName,
                content: messages[i].content,
              },
            ]);
          }
        } else {
          lumpsTemp.push([
            currentDate,
            [
              [
                {
                  id: messages[i].id,
                  date: timeCurrent,
                  userId: messages[i].userId,
                  userName: messages[i].userName,
                  content: messages[i].content,
                },
              ],
            ],
          ]);
        }
      }
    }
    return lumpsTemp;
  }

  if (loading) {
    return (
      <>
        <div className="loading__wrap">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className="loading__wrap">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }

  const handleOnChange = (text, medium) => {
    const newState = { ...input, ['content']: text };
    setInput(newState);
  };
  // console.log('INPUT', input);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (input.content !== '') {
      sendMessage();
    }
  };

  const onEnterPress = (event) => {
    if (event.keyCode == 13 && event.shiftKey == false) {
      event.preventDefault();
      if (input.content !== '') {
        sendMessage();
      }
    }
  };

  console.log('INPUT CONTENT', input.content);

  return (
    <>
      <div className="messenger__user__wrap">
        <div className="resume__messenger">
          <p>
            Messaging and <span>feedback</span>
          </p>
        </div>
        <div className="messenger__main">
          {createLumps(data.messagesChat).length
            ? createLumps(data.messagesChat).map((messWrap, indexWrap) => (
                <div key={indexWrap}>
                  <p
                    className="has-text-centered"
                    style={{ fontSize: '12px', color: '#fff' }}
                  >
                    {' '}
                    {messWrap[0]}{' '}
                  </p>
                  {messWrap[1].map((mess, index) => (
                    <div className="message__lump" key={index}>
                      <div
                        className={
                          mess[0].userId === userId
                            ? 'message__pattern __rec'
                            : 'message__pattern __sender'
                        }
                      >
                        <div className="message__chat">
                          {mess.map((bubble) => (
                            <div
                              key={bubble.id}
                              className={
                                bubble.userId === userId
                                  ? 'bubble recipient'
                                  : 'bubble sender'
                              }
                              dangerouslySetInnerHTML={{
                                __html: bubble.content,
                              }}
                            />
                          ))}
                        </div>
                        <div
                          className={
                            mess[0].userId === userId
                              ? 'message__user __rec'
                              : 'message__user __sender'
                          }
                        >
                          <img src={userIcon} alt="" />
                        </div>
                      </div>
                      <p
                        className={
                          mess[0].userId === userId
                            ? '__rec __whoby'
                            : '__sender __whoby'
                        }
                      >
                        {mess[0].userName + ' | ' + mess[0].date}
                      </p>
                    </div>
                  ))}
                </div>
              ))
            : ''}
          <div ref={bottomRef} />
        </div>

        <div className="messenger__input">
          <form onSubmit={handleFormSubmit}>
            <div className="mess__input__wrap">
              <div className="columns is-vcentered is-gapless">
                <div className="column is-10">
                  <div className="__control">
                    {/* <textarea
                      className="textarea has-fixed-size"
                      type="text"
                      placeholder="Type your message here...."
                      rows="3"
                      name="content"
                      value={input.content}
                      onChange={handleOnChange}
                      onKeyDown={onEnterPress}
                    /> */}
                    <div className="text__area__mock">
                      <Editor
                        tag="pre"
                        text={input.content}
                        onChange={handleOnChange}
                        // onKeyDown={onEnterPress}
                        options={{
                          toolbar: {
                            buttons: ['bold', 'italic', 'underline'],
                            static: false,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="column is-2" style={{ textAlign: 'center' }}>
                  <button className="button messenger__button" type="submit">
                    <img src={sendIcon} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
