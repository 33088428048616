import React, { useState, useContext, useEffect, useCallback } from 'react';
import NavSide from '../Components/NavSide';
import NavTop from '../Components/NavTop';
import saveImage from './../images/save.svg';
import { AuthContext } from '../Context/authContext';
import { gql, useQuery, useMutation } from '@apollo/client';
import DatePicker from 'react-datepicker';
import Messenger from '../Components/Messenger';
import { NotifContext } from '../Context/notifContext';
import { useDropzone } from 'react-dropzone';
import exportIcon from './../images/export.svg';
import fileIcon from './../images/file-uploader.svg';
import { saveAs } from 'file-saver';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router';
import Uploader from '../Components/Uploader';

const EDIT_REFERENCE = gql`
  mutation EditReference($editReferenceInput: EditReferenceInput) {
    EditReference(editReferenceInput: $editReferenceInput)
  }
`;

const CREATE_REFERENCE = gql`
  mutation Mutation($createReferenceInput: CreateReferenceInput) {
    CreateReference(createReferenceInput: $createReferenceInput)
  }
`;

const REFERENCE_QUERY = gql`
  query Query($userId: String!) {
    reference(userId: $userId) {
      id
      userId
      step
      comName
      comCity
      comCountry
      comJob
      comJobStart
      comJobEnd
      comTeamSize
      comRecName
      comRec
      endName
      adminRefName
      adminPhone
      adminEmail
      dateCreated
      link
      gender
      name
    }
  }
`;

export default function References() {
  const { user } = useContext(AuthContext);

  const userId = user.id.toString();
  const userName = user.name;
  const chatId = user.id.toString() + 'ref';
  const instanceId = user.id.toString() + 'ref';

  let navigate = useNavigate();

  useEffect(() => {
    if (user.type !== 'user') {
      navigate('/dashboard');
    }
  }, []);

  const { loading, error, data, refetch } = useQuery(REFERENCE_QUERY, {
    variables: { userId },
  });

  const dateNew = new Date(2020, 1, 1);

  const initialState = {
    userId: userId,
    step: '1',
    comName: '',
    comCity: '',
    comCountry: '',
    comJob: '',
    comJobStart: dateNew,
    comJobEnd: dateNew,
    comTeamSize: '',
    comRecName: '',
    comRec: '',
    endName: '',
    name: '',
    gender: '',
  };

  const [input, setInput] = useState(initialState);
  const [references, setReferences] = useState([]);
  const [currentActive, setCurrentActive] = useState(0);
  const { handleNotificationSuccess } = useContext(NotifContext);
  const [currentFile, setCurrentFile] = useState(null);

  const [editReference, { loading: loadingA, error: errorA, data: dataA }] =
    useMutation(EDIT_REFERENCE, {
      onCompleted: () => {
        refetch();
        handleNotificationSuccess(true);
      },
      onError: () => handleNotificationSuccess(false),
    });

  const [createReference, { loading: loadingB, error: errorB, data: dataB }] =
    useMutation(CREATE_REFERENCE, {
      onCompleted: () => {
        refetch();
        setCurrentActive(0);
        handleNotificationSuccess(true);
      },
      onError: () => handleNotificationSuccess(false),
    });

  const onDrop = useCallback(
    ([file]) => {
      setCurrentFile(file);
    },
    [createReference]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (loading === false) {
      if (data.reference.length) {
        setInput(initialState);
      }
    }
  }, [data]);

  const handleOnChange = (event) => {
    const newState = { ...input, [event.target.name]: event.target.value };
    setInput(newState);
  };
  const handleOnChangeDate = (name, date) => {
    const newState = { ...input, [name]: date.toString() };
    setInput(newState);
  };

  function clearInput() {
    if (loading === false) {
      if (data.reference.length) {
        setInput(initialState);
      }
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const createReferenceInput = { ...input, file: currentFile };
    // console.log("ref", createReferenceInput)
    createReference({ variables: { createReferenceInput } });
  };

  useEffect(() => {
    if (loading === false) {
      if (data !== []) {
        if (data.reference.length) {
          setReferences(data.reference);
        }
      } else {
        setReferences([]);
      }
    }
  }, [data]);

  if (loading) {
    return (
      <>
        <NavSide current="ref" />
        <div className="loading__wrap__full">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }

  function downloadFile(link) {
    saveAs(link, link.split('/')[3]);
  }

  return (
    <>
      <NavTop current="ref" />
      <NavSide current="ref" />
      <div className="main__section">
        <div className="main__container">
          <div className="title__wrap">
            <h2>Manage your connections</h2>
            <h1>References Overview</h1>
          </div>
          <div className="content__wrap">
            <p>
              The reference section. They are three phases, step one is provided
              the required information, step two is recieving feedback from an
              Admin account, and step three is reviewing their feedback.
            </p>
          </div>
          <div className="ver__wrap ref__alt">
            <div className="columns is-gapless">
              <div className="column is-5">
                <Messenger
                  userId={userId}
                  chatId={chatId}
                  userName={userName}
                />
                <Uploader instanceId={instanceId} userName={userName} />
              </div>
              <div className="column is-7">
                <div className="question__wrap __alt">
                  <div className="questions__top">
                    <h3>
                      <span>{references.length}</span> References Found
                    </h3>
                  </div>
                  <div className="question__main">
                    {references.length
                      ? references.map((reference, i) => (
                          <div key={reference.id}>
                            <div>
                              <div className="question__header __plus">
                                <h3>
                                  Reference {i + 1} - {reference.dateCreated}{' '}
                                  <span>
                                    {reference.step === '2'
                                      ? 'Completed'
                                      : 'In Review'}
                                  </span>
                                </h3>
                                <div className="__controls">
                                  <button
                                    className={
                                      currentActive === i + 1
                                        ? 'button'
                                        : 'button __new'
                                    }
                                    onClick={
                                      currentActive === i + 1
                                        ? () => setCurrentActive(0)
                                        : () => setCurrentActive(i + 1)
                                    }
                                  >
                                    {currentActive === i + 1 ? 'Hide' : 'Show'}
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                currentActive !== i + 1 ? 'display__none' : ''
                              }
                            >
                              <div className="question__body __alt">
                                <h5>Question 1</h5>
                                <p>Your Full Legal Name:</p>
                                <h3>{reference.name}</h3>
                              </div>
                              <div className="question__body __alt">
                                <h5>Question 2</h5>
                                <p>Your gender:</p>
                                <h3>{reference.gender}</h3>
                              </div>
                              <div className="question__body __alt">
                                <h5>Question 3</h5>
                                <p>Resume Company Name:</p>
                                <h3>{reference.comName}</h3>
                              </div>
                              <div className="question__body">
                                <h5>Question 4</h5>
                                <p>Resume Company City:</p>
                                <h3>{reference.comCity}</h3>
                              </div>
                              <div className="question__body">
                                <h5>Question 5</h5>
                                <p>Resume Company Country:</p>
                                <h3>{reference.comCountry}</h3>
                              </div>
                              <div className="question__body">
                                <h5>Question 6</h5>
                                <p>Resume Company Job Title:</p>
                                <h3>{reference.comJob}</h3>
                              </div>
                              <div className="question__body">
                                <h5>Question 7</h5>
                                <p>Resume Company Employment Start Date:</p>
                                <h3>
                                  {reference.comJobStart.split('00:00:00')[0]}
                                </h3>
                              </div>
                              <div className="question__body">
                                <h5>Question 8</h5>
                                <p>Resume Company Employment End Date:</p>
                                <h3>
                                  {reference.comJobEnd.split('00:00:00')[0]}
                                </h3>
                              </div>
                              <div className="question__body">
                                <h5>Question 9</h5>
                                <p>
                                  Company Team Size (PO, DEV, TESTERS, BA, SCRUM
                                  MASTER):
                                </p>
                                <h3>{reference.comTeamSize}</h3>
                              </div>
                              <div className="question__body">
                                <h5>Question 10</h5>
                                <p>Hiring Company Recruiter Full Name:</p>
                                <h3>{reference.comRecName}</h3>
                              </div>
                              <div className="question__body">
                                <h5>Question 11</h5>
                                <p>Recruiter Company Name:</p>
                                <h3>{reference.comRec}</h3>
                              </div>
                              <div className="question__body">
                                <h5>Question 12</h5>
                                <p>End Company Name:</p>
                                <h3>{reference.endName}</h3>
                              </div>
                              <div className="question__body">
                                <h5>Question 13</h5>
                                <p>Resume that was uploaded:</p>
                                {reference.link === '' ? (
                                  <div className="mail__no__attachment">
                                    <img
                                      src="data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTIuMDAyIDIuMDA1YzUuNTE4IDAgOS45OTggNC40OCA5Ljk5OCA5Ljk5NyAwIDUuNTE4LTQuNDggOS45OTgtOS45OTggOS45OTgtNS41MTcgMC05Ljk5Ny00LjQ4LTkuOTk3LTkuOTk4IDAtNS41MTcgNC40OC05Ljk5NyA5Ljk5Ny05Ljk5N3ptMCA4LjkzMy0yLjcyMS0yLjcyMmMtLjE0Ni0uMTQ2LS4zMzktLjIxOS0uNTMxLS4yMTktLjQwNCAwLS43NS4zMjQtLjc1Ljc0OSAwIC4xOTMuMDczLjM4NC4yMTkuNTMxbDIuNzIyIDIuNzIyLTIuNzI4IDIuNzI4Yy0uMTQ3LjE0Ny0uMjIuMzQtLjIyLjUzMSAwIC40MjcuMzUuNzUuNzUxLjc1LjE5MiAwIC4zODQtLjA3My41My0uMjE5bDIuNzI4LTIuNzI4IDIuNzI5IDIuNzI4Yy4xNDYuMTQ2LjMzOC4yMTkuNTMuMjE5LjQwMSAwIC43NS0uMzIzLjc1LS43NSAwLS4xOTEtLjA3My0uMzg0LS4yMi0uNTMxbC0yLjcyNy0yLjcyOCAyLjcxNy0yLjcxN2MuMTQ2LS4xNDcuMjE5LS4zMzguMjE5LS41MzEgMC0uNDI1LS4zNDYtLjc1LS43NS0uNzUtLjE5MiAwLS4zODUuMDczLS41MzEuMjJ6IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4="
                                      alt=""
                                    />
                                    <p>No file was attatched</p>
                                  </div>
                                ) : (
                                  <div className="uploader__item">
                                    <div className="__left">
                                      <div className="img__wrap">
                                        <img src={fileIcon} alt="" />
                                      </div>
                                      <h5>
                                        {
                                          reference.link
                                            .split('/')[3]
                                            .split('.')[0]
                                        }
                                      </h5>
                                      <h6>
                                        <span>
                                          {
                                            reference.link
                                              .split('/')[3]
                                              .split('.')[1]
                                          }
                                        </span>
                                      </h6>
                                    </div>
                                    <div className="__right">
                                      <button
                                        className="button"
                                        onClick={() =>
                                          downloadFile(reference.link)
                                        }
                                      >
                                        <img src={exportIcon} alt="" />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {reference.step === '2' ? (
                                <div className="question__admin">
                                  <h4>Admin Provided Information</h4>
                                  <div className="question__body">
                                    <p>Reference name:</p>
                                    <h3>{reference.adminRefName}</h3>
                                  </div>
                                  <div className="question__body">
                                    <p>Reference phone:</p>
                                    <h3>{reference.adminPhone}</h3>
                                  </div>
                                  <div className="question__body">
                                    <p>Reference email:</p>
                                    <h3>{reference.adminEmail}</h3>
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        ))
                      : ''}

                    <div className="question__header __plus">
                      <h3>Add a new Reference</h3>
                      <div className="__controls">
                        {/* <button className="button __new"><img src={plusOneImage} alt="" class="__uni" /> Add new reference</button> */}
                        <button
                          className={
                            currentActive === references.length + 2
                              ? 'button'
                              : 'button __new'
                          }
                          onClick={
                            currentActive === references.length + 2
                              ? () => {
                                  setCurrentActive(0);
                                  setCurrentFile(null);
                                  setInput(initialState);
                                }
                              : () => setCurrentActive(references.length + 2)
                          }
                        >
                          {currentActive !== references.length + 2
                            ? 'Add new reference'
                            : 'Clear new reference'}
                        </button>
                      </div>
                    </div>
                    {currentActive === references.length + 2 ? (
                      <>
                        <form onSubmit={handleFormSubmit}>
                          <div className="question__body __alt">
                            <h5>Question 1</h5>
                            <p>Your Full Legal Name</p>
                            <input
                              type="text"
                              className="input"
                              name="name"
                              value={input.name}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div className="question__body __alt">
                            <h5>Question 2</h5>
                            <p>Your Gender:</p>
                            <input
                              type="text"
                              className="input"
                              name="gender"
                              value={input.gender}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div className="question__body __alt">
                            <h5>Question 3</h5>
                            <p>Resume Company Name:</p>
                            <input
                              type="text"
                              className="input"
                              name="comName"
                              value={input.comName}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div className="question__body">
                            <h5>Question 4</h5>
                            <p>Resume Company City:</p>
                            <input
                              type="text"
                              className="input"
                              name="comCity"
                              value={input.comCity}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div className="question__body">
                            <h5>Question 5</h5>
                            <p>Resume Company Country:</p>
                            <input
                              type="text"
                              className="input"
                              name="comCountry"
                              value={input.comCountry}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div className="question__body">
                            <h5>Question 6</h5>
                            <p>Resume Company Job Title:</p>
                            <input
                              type="text"
                              className="input"
                              name="comJob"
                              value={input.comJob}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div className="question__body">
                            <h5>Question 7</h5>
                            <p>Resume Company Employment Start Date:</p>
                            <DatePicker
                              selected={new Date(input.comJobStart)}
                              name="comJobStart"
                              onChange={(date) =>
                                handleOnChangeDate('comJobStart', date)
                              }
                            />
                          </div>
                          <div className="question__body">
                            <h5>Question 8</h5>
                            <p>Resume Company Employment End Date:</p>
                            <DatePicker
                              selected={new Date(input.comJobEnd)}
                              name="comJobEnd"
                              onChange={(date) =>
                                handleOnChangeDate('comJobEnd', date)
                              }
                            />
                          </div>
                          <div className="question__body">
                            <h5>Question 9</h5>
                            <p>
                              Company Team Size (PO, DEV, TESTERS, BA, SCRUM
                              MASTER):
                            </p>
                            <input
                              type="text"
                              className="input"
                              name="comTeamSize"
                              value={input.comTeamSize}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div className="question__body">
                            <h5>Question 10</h5>
                            <p>Hiring Company Recruiter Full Name:</p>
                            <input
                              type="text"
                              className="input"
                              name="comRecName"
                              value={input.comRecName}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div className="question__body">
                            <h5>Question 11</h5>
                            <p>Recruiter Company Name:</p>
                            <input
                              type="text"
                              className="input"
                              name="comRec"
                              value={input.comRec}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div className="question__body">
                            <h5>Question 12</h5>
                            <p>End Company Name:</p>
                            <input
                              type="text"
                              className="input"
                              name="endName"
                              value={input.endName}
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                          <div className="question__body">
                            <h5>Question 13</h5>
                            <p>Upload your resume:</p>
                            {currentFile === null ? (
                              <div className="uploader__input">
                                <div
                                  {...getRootProps({ className: 'dropzone' })}
                                >
                                  <input {...getInputProps()} />
                                  <p>Click to select files</p>
                                </div>
                              </div>
                            ) : (
                              <div className="uploader__item">
                                <div className="__left">
                                  <div className="img__wrap">
                                    <img src={fileIcon} alt="" />
                                  </div>
                                  <h5>{currentFile.name}</h5>
                                  <h6>
                                    <span>{currentFile.type}</span>
                                  </h6>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="question__body">
                            <button className="button __edit" type="submit">
                              {' '}
                              <img src={saveImage} alt="" /> Save changes
                            </button>
                          </div>
                        </form>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
