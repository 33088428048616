import React, { useState, useEffect, useContext } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import DatePicker from 'react-datepicker';
import saveImage from './../images/save.svg';
import { AuthContext } from '../Context/authContext';
import Messenger from '../Components/Messenger';
import Uploader from '../Components/Uploader';
import { saveAs } from 'file-saver';
import exportIcon from './../images/export.svg';
import fileIcon from './../images/file-uploader.svg';
import { NotifContext } from '../Context/notifContext';

import 'react-datepicker/dist/react-datepicker.css';

const EDIT_REFERENCE = gql`
  mutation EditReference($editReferenceInput: EditReferenceInput) {
    EditReference(editReferenceInput: $editReferenceInput)
  }
`;

const REFERENCE_QUERY = gql`
  query Query($userId: String!) {
    reference(userId: $userId) {
      id
      userId
      step
      comName
      comCity
      comCountry
      comJob
      comJobStart
      comJobEnd
      comTeamSize
      comRecName
      comRec
      endName
      adminRefName
      adminPhone
      adminEmail
      dateCreated
      link
      name
      gender
    }
  }
`;

export default function RefrencesAdmin(props) {
  const { userId } = props;

  const { user } = useContext(AuthContext);
  const userName = user.name;
  const adminUserId = user.id.toString();
  const chatId = userId.toString() + 'ref';
  const instanceId = userId + 'ref';
  const { handleNotificationSuccess } = useContext(NotifContext);

  const { loading, error, data, refetch } = useQuery(REFERENCE_QUERY, {
    variables: { userId },
  });

  // console.log(data)

  const [references, setReferences] = useState([]);

  const [currentActive, setCurrentActive] = useState(0);

  const dateNew = new Date(2020, 1, 1);

  const initialState = {
    userId: userId,
    step:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].step
          : '1'
        : '1',
    comName:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].comName
          : ''
        : '',
    comCity:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].comCity
          : ''
        : '',
    comCountry:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].comCountry
          : ''
        : '',
    comJob:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].comJob
          : ''
        : '',
    comJobStart:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].comJobStart
          : dateNew
        : dateNew,
    comJobEnd:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].comJobEnd
          : dateNew
        : dateNew,
    comTeamSize:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].comTeamSize
          : ''
        : '',
    comRecName:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].comRecName
          : ''
        : '',
    comRec:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].comRec
          : ''
        : '',
    endName:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].endName
          : ''
        : '',
    adminRefName:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].adminRefName
          : ''
        : '',
    adminEmail:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].adminEmail
          : ''
        : '',
    adminPhone:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].adminPhone
          : ''
        : '',
    name:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].name
          : ''
        : '',
    gender:
      undefined !== data
        ? data.reference.length
          ? data.reference[0].gender
          : ''
        : '',
  };

  const [input, setInput] = useState(initialState);

  const [editReference, { loading: loadingA, error: errorA, data: dataA }] =
    useMutation(EDIT_REFERENCE, {
      onCompleted: () => {
        refetch();
        handleNotificationSuccess(true);
      },
      onError: () => {
        console.log(error);
        handleNotificationSuccess(false);
      },
    });

  // console.log("EROROER", errorA)

  // useEffect(() => {
  //     if(loading === false){
  //       if(data.reference.length){
  //         setInput(initialState)
  //       }
  //     }
  // }, [loading])

  function changeCurrentInput(y) {
    const newTemp = {
      id: data.reference[y].id,
      userId: userId,
      step: '2',
      comName: data.reference[y].comName,
      comCity: data.reference[y].comCity,
      comCountry: data.reference[y].comCountry,
      comJob: data.reference[y].comJob,
      comJobStart: data.reference[y].comJobStart,
      comJobEnd: data.reference[y].comJobEnd,
      comTeamSize: data.reference[y].comTeamSize,
      comRecName: data.reference[y].comRecName,
      comRec: data.reference[y].comRec,
      endName: data.reference[y].endName,
      adminRefName: data.reference[y].adminRefName,
      adminEmail: data.reference[y].adminEmail,
      adminPhone: data.reference[y].adminPhone,
      name: data.reference[y].name,
      gender: data.reference[y].gender,
    };
    setInput(newTemp);
  }

  const handleOnChange = (event) => {
    const newState = { ...input, [event.target.name]: event.target.value };
    setInput(newState);
  };
  const handleOnChangeDate = (name, date) => {
    const newState = { ...input, [name]: date.toString() };
    setInput(newState);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const editReferenceInput = input;
    editReference({ variables: { editReferenceInput } });
  };

  // console.log(input.step)

  useEffect(() => {
    if (loading === false) {
      if (data !== []) {
        if (data.reference.length) {
          setReferences(data.reference);
        }
      } else {
        setReferences([]);
      }
    }
  }, [data]);

  // console.log("DATA", references)

  if (loading) {
    return (
      <>
        <div className="loading__wrap__full">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }

  function downloadFile(link) {
    saveAs(link, link.split('/')[3]);
  }

  return (
    <>
      <div className="nothing__found">
        <h3>
          <span>{references.length}</span> References Found
        </h3>
        <p>The user has provided information for this section.</p>
        {/* <button className="button"> <img src={mailImage} alt="" /> Send Notification</button> */}
      </div>
      <div className="ver__wrap ref__alt">
        <div className="columns is-gapless">
          <div className="column is-5">
            <Messenger
              userId={adminUserId}
              chatId={chatId}
              userName={userName}
            />
            <Uploader instanceId={instanceId} userName={userName} />
          </div>
          <div className="column is-7">
            <div
              className="question__wrap __alt"
              style={{ marginTop: '2rem', paddingTop: '0rem' }}
            >
              <div className="question__main">
                {references.length
                  ? references.map((reference, i) => (
                      <div key={reference.id}>
                        <div>
                          <div className="question__header __plus">
                            <h3>
                              Reference {i + 1} - {reference.dateCreated}{' '}
                              <span>
                                {reference.step === '2'
                                  ? 'Completed'
                                  : 'In Review'}
                              </span>
                            </h3>
                            <div className="__controls">
                              <button
                                className={
                                  currentActive === i + 1
                                    ? 'button'
                                    : 'button __new'
                                }
                                onClick={
                                  currentActive === i + 1
                                    ? () => setCurrentActive(0)
                                    : () => {
                                        setCurrentActive(i + 1);
                                        changeCurrentInput(i);
                                      }
                                }
                              >
                                {currentActive === i + 1 ? 'Hide' : 'Show'}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div
                          className={
                            currentActive !== i + 1 ? 'display__none' : ''
                          }
                        >
                          <form onSubmit={handleFormSubmit}>
                            <div className="question__body __alt">
                              <h5>Question 1</h5>
                              <p>Your Full Legal Name</p>
                              <input
                                type="text"
                                className="input"
                                name="name"
                                value={input.name}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                            <div className="question__body __alt">
                              <h5>Question 2</h5>
                              <p>Your Gender:</p>
                              <input
                                type="text"
                                className="input"
                                name="gender"
                                value={input.gender}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                            <div className="question__body __alt">
                              <h5>Question 1</h5>
                              <p>Resume Company Name:</p>
                              <input
                                type="text"
                                className="input"
                                name="comName"
                                value={input.comName}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                            <div className="question__body">
                              <h5>Question 2</h5>
                              <p>Resume Company City:</p>
                              <input
                                type="text"
                                className="input"
                                name="comCity"
                                value={input.comCity}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                            <div className="question__body">
                              <h5>Question 3</h5>
                              <p>Resume Company Country:</p>
                              <input
                                type="text"
                                className="input"
                                name="comCountry"
                                value={input.comCountry}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                            <div className="question__body">
                              <h5>Question 4</h5>
                              <p>Resume Company Job Title:</p>
                              <input
                                type="text"
                                className="input"
                                name="comJob"
                                value={input.comJob}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                            <div className="question__body">
                              <h5>Question 5</h5>
                              <p>Resume Company Employment Start Date:</p>
                              <DatePicker
                                selected={new Date(input.comJobStart)}
                                name="comJobStart"
                                onChange={(date) =>
                                  handleOnChangeDate('comJobStart', date)
                                }
                              />
                            </div>
                            <div className="question__body">
                              <h5>Question 6</h5>
                              <p>Resume Company Employment End Date:</p>
                              <DatePicker
                                selected={new Date(input.comJobEnd)}
                                name="comJobEnd"
                                onChange={(date) =>
                                  handleOnChangeDate('comJobEnd', date)
                                }
                              />
                            </div>
                            <div className="question__body">
                              <h5>Question 7</h5>
                              <p>
                                Company Team Size (PO, DEV, TESTERS, BA, SCRUM
                                MASTER):
                              </p>
                              <input
                                type="text"
                                className="input"
                                name="comTeamSize"
                                value={input.comTeamSize}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                            <div className="question__body">
                              <h5>Question 8</h5>
                              <p>Hiring Company Recruiter Full Name:</p>
                              <input
                                type="text"
                                className="input"
                                name="comRecName"
                                value={input.comRecName}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                            <div className="question__body">
                              <h5>Question 9</h5>
                              <p>Recruiter Company Name:</p>
                              <input
                                type="text"
                                className="input"
                                name="comRec"
                                value={input.comRec}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                            <div className="question__body">
                              <h5>Question 10</h5>
                              <p>End Company Name:</p>
                              <input
                                type="text"
                                className="input"
                                name="endName"
                                value={input.endName}
                                onChange={handleOnChange}
                                required
                              />
                            </div>
                            <div className="question__body">
                              <h5>Question 11</h5>
                              <p>Resume that was uploaded:</p>
                              {reference.link === '' ? (
                                <div className="mail__no__attachment">
                                  <img
                                    src="data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTIuMDAyIDIuMDA1YzUuNTE4IDAgOS45OTggNC40OCA5Ljk5OCA5Ljk5NyAwIDUuNTE4LTQuNDggOS45OTgtOS45OTggOS45OTgtNS41MTcgMC05Ljk5Ny00LjQ4LTkuOTk3LTkuOTk4IDAtNS41MTcgNC40OC05Ljk5NyA5Ljk5Ny05Ljk5N3ptMCA4LjkzMy0yLjcyMS0yLjcyMmMtLjE0Ni0uMTQ2LS4zMzktLjIxOS0uNTMxLS4yMTktLjQwNCAwLS43NS4zMjQtLjc1Ljc0OSAwIC4xOTMuMDczLjM4NC4yMTkuNTMxbDIuNzIyIDIuNzIyLTIuNzI4IDIuNzI4Yy0uMTQ3LjE0Ny0uMjIuMzQtLjIyLjUzMSAwIC40MjcuMzUuNzUuNzUxLjc1LjE5MiAwIC4zODQtLjA3My41My0uMjE5bDIuNzI4LTIuNzI4IDIuNzI5IDIuNzI4Yy4xNDYuMTQ2LjMzOC4yMTkuNTMuMjE5LjQwMSAwIC43NS0uMzIzLjc1LS43NSAwLS4xOTEtLjA3My0uMzg0LS4yMi0uNTMxbC0yLjcyNy0yLjcyOCAyLjcxNy0yLjcxN2MuMTQ2LS4xNDcuMjE5LS4zMzguMjE5LS41MzEgMC0uNDI1LS4zNDYtLjc1LS43NS0uNzUtLjE5MiAwLS4zODUuMDczLS41MzEuMjJ6IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4="
                                    alt=""
                                  />
                                  <p>No file was attatched</p>
                                </div>
                              ) : (
                                <div className="uploader__item">
                                  <div className="__left">
                                    <div className="img__wrap">
                                      <img src={fileIcon} alt="" />
                                    </div>
                                    <h5>
                                      {
                                        reference.link
                                          .split('/')[3]
                                          .split('.')[0]
                                      }
                                    </h5>
                                    <h6>
                                      <span>
                                        {
                                          reference.link
                                            .split('/')[3]
                                            .split('.')[1]
                                        }
                                      </span>
                                    </h6>
                                  </div>
                                  <div className="__right">
                                    <button
                                      className="button"
                                      onClick={() =>
                                        downloadFile(reference.link)
                                      }
                                    >
                                      <img src={exportIcon} alt="" />
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="question__admin">
                              <h4>Admin Provided Information</h4>
                              <div className="question__body">
                                <p>Reference name:</p>
                                <input
                                  type="text"
                                  className="input"
                                  name="adminRefName"
                                  value={input.adminRefName}
                                  onChange={handleOnChange}
                                  required
                                />
                              </div>
                              <div className="question__body">
                                <p>Reference phone:</p>
                                <input
                                  type="text"
                                  className="input"
                                  name="adminPhone"
                                  value={input.adminPhone}
                                  onChange={handleOnChange}
                                  required
                                />
                              </div>
                              <div className="question__body">
                                <p>Reference email:</p>
                                <input
                                  type="text"
                                  className="input"
                                  name="adminEmail"
                                  value={input.adminEmail}
                                  onChange={handleOnChange}
                                  required
                                />
                              </div>
                            </div>
                            <div className="question__body">
                              <button className="button __edit" type="submit">
                                {' '}
                                <img src={saveImage} alt="" /> Save changes
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    ))
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
