import dlexImage from "./../images/icon-dark-bg.svg"
// import googleImage from "./../images/google_g_logo.svg"
// import {useState} from 'react';
// import check from "./../images/check-circle.svg"
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../Context/authContext";
import { useMutation, gql } from "@apollo/client";
import { useNavigate } from "react-router";

const LOGIN_USER = gql`
  mutation login(
    $loginInput: LoginInput
  ) {
    loginUser(
      loginInput: $loginInput
    ){
      email
      name
      type
      token
    }
  }

`


export default function Login() {

  const context = useContext(AuthContext)
  let navigate = useNavigate

  const initialState = {
    email: '',
    password: ''
  }

  const [ input, setInput ] = useState( initialState );
  const [ error, setError ] = useState( false );
  const [ errors, setErrors ] = useState( false );
  const [ remember, setRemember ] = useState( false );

  const [ loginUser, {loading}] = useMutation(LOGIN_USER, {
    update(proxy, {data: {loginUser: userData}}) {
        // console.log("SUCCESS")
        context.login(userData)
        window.location.reload(false);
    },
    onError({graphQLErrors}) {
        setErrors(graphQLErrors)
        // console.log("ERROR")
        setError(true)
    },
    variables: {loginInput: input}
  })

  // console.log(errors)

  const handleFormSubmit = (e) => {
    e.preventDefault()
    loginUser()
    
  }

  if(error === true){
		setTimeout(function () {
      setError(false)
    }, 5000);
	}

  const handleOnChange = ( event ) => {
    const newState = { ...input, [event.target.name]: event.target.value };
    setInput( newState );
  };

  // console.log(input)


  return (
    <div className="login__wrap login__uni">
        <div className="login__side">
            <div className="login__cont">
                <img className="login__image" src={dlexImage} alt=""/>
                <h1 style={{marginBottom: "5.5rem"}}>Sign in to your jobs93 Account</h1>

                <form className="login__form" onSubmit={handleFormSubmit}>
                    <div className="login__item field">
                        <input type="text" className="input" name="email" onChange={ handleOnChange } placeholder="Your email address"/>
                    </div>
                    <div className="login__item field">
                      <input type="password" className="input" name="password" onChange={ handleOnChange } placeholder="Your password"/>
                    </div>
                    {/* LOGIN ERROR */}
                    <div className="login__remember">
                        <div className="__left">
                            <button className={remember === false ? "button button__remember" : "button button__remember __active"} type="button" onClick={remember === false ? ()=> setRemember(true) : ()=> setRemember(false)}></button>
                            <p>Remember me</p>
                        </div>
                        
                        <a href="/"><p>Forgot your password?</p></a>
                    </div>
                    {
                      error === true ?
                      <div className="login__error">
                        <p>Incorrect Username or Password, please double check the provided credentials.</p>
                      </div>
                      : ""
                    }
                    <button type="submit" className="btn btn-primary login__submit">Sign in</button>
                  </form>

                  {/* LOGIN FOOTER */}
                  <div className="login__footer">
                    <a href="/register"><button className="button login__new">Don't have an account? <span>Sign up</span></button></a>
                    <div className="login__footer__bottom">
                      <a href="/">Home</a>
                      <div className="__dec"></div>
                      <a href="/">Contact</a>
                      <div className="__dec"></div>
                      <a href="/">Privacy & terms</a>
                    </div>
                  </div>
            </div>
        </div>
    </div>
  )
}
