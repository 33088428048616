import React, {useCallback} from 'react'
import refetchIcon from "./../images/refetch-icon.svg"
import fileIcon from "./../images/file-uploader.svg"
import exportIcon from "./../images/export.svg"
import { useMutation, gql, useQuery } from "@apollo/client";
import {useDropzone} from 'react-dropzone'
import { saveAs } from 'file-saver';

const UPLOAD_FILE = gql`
      mutation FileUpload($file: [Upload]!, $instanceId: String!, $userName: String!) {
      fileUpload(file: $file, instanceId: $instanceId, userName: $userName) {
        url
     }
   }
`;

const FILE_QUERY = gql`
query Files($instanceId: String!) {
  files(instanceId: $instanceId) {
    id
    name
    date
    instanceId
    userName
    link
  }
}
`

const FILE_SUBSCRIPTION = gql`
  subscription Subscription($instanceId: String!) {
    files(instanceId: $instanceId) {
    id
    name
    date
    instanceId
    userName
    link
  }
}`

export default function Uploader(props) {

    const {instanceId, userName} = props

    // const { loading, error, data, refetch } = useQuery(FILE_QUERY, {
    //     variables: {instanceId},
    // });

    const { subscribeToMore, loading, error, data, refetch  } = useQuery(FILE_QUERY, {
        variables: {instanceId},
    });

    subscribeToMore({
    document: FILE_SUBSCRIPTION,
    variables: { instanceId },
    updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        // console.log(subscriptionData.data)
        const newFeedItem = subscriptionData.data.files;

        return Object.assign({}, prev, {
            files: {...prev.files, newFeedItem}
        
        });
    }
    })

    const [fileUpload, { loading: loadingA, error: errorA, data: dataA }] = useMutation(UPLOAD_FILE, {
        onCompleted: ()=> refetch(),
    });


    const onDrop = useCallback(
        ([file]) => {
            fileUpload({ variables: { file, instanceId, userName } });
        }, [fileUpload]
    )

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    function downloadFile(link) {
        saveAs(link, link.split("/")[3])
    }

    if(loading){
        return <>
        <div className="loading__wrap">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        </>
    }
    
    if(error){
        return <>
        <div className="loading__wrap">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        </>
    }
  return (
    <div className="file__upload__wrap">
        <div className="file__upload__title">
            <p>File <span>uploader</span></p>
            <button className="button" onClick={()=> refetch()}><img src={refetchIcon} alt="" /></button>
        </div>
        <div className="uploader__main">

            {
                data.files.length ?
                data.files.map(file =>
                <div className={file.name.split(".")[1] === "jpg" ? "file__dl__main __alt" : "file__dl__main"} key={file.id}>

                    {
                        file.name.split(".")[1] === "jpg" ?
                        <>
                        <img src={file.link} alt="" className="uploader__img" />
                        <div className="uploader__item">
                            <div className="__left">
                                <h5>{file.name}</h5>
                                <h6><span>By</span> {file.userName}</h6>
                                <h6><span>Date</span> {file.date}</h6>
                            </div>
                            <div className="__right">
                                <button className="button" onClick={()=>downloadFile(file.link)}><img src={exportIcon} alt="" /></button>
                            </div>
                        </div>
                        </>
                        :
                        file.name.split(".")[1] === "png" ?
                        <>
                        <img src={file.link} alt="" className="uploader__img" />
                        <div className="uploader__item">
                            <div className="__left">
                                <h5>{file.name}</h5>
                                <h6><span>By</span> {file.userName}</h6>
                                <h6><span>Date</span> {file.date}</h6>
                            </div>
                            <div className="__right">
                                <button className="button" onClick={()=>downloadFile(file.link)}><img src={exportIcon} alt="" /></button>
                            </div>
                        </div>
                        </>
                        :
                        <div className="uploader__item" key={file.id}>
                            <div className="__left">
                                <div className="img__wrap">
                                    <img src={fileIcon} alt="" />
                                </div>
                                <h5>{file.name}</h5>
                                <h6><span>By</span> {file.userName}</h6>
                                <h6><span>Date</span> {file.date}</h6>
                            </div>
                            <div className="__right">
                                <button className="button" onClick={()=>downloadFile(file.link)}><img src={exportIcon} alt="" /></button>
                            </div>
                        </div>
                    }
                    
                </div>
                )
                : ""
            }
            

        </div>
        <div className="uploader__input">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Click to select files</p>
            </div>
        </div>
    </div>
  )
}
