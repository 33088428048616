import React from 'react'
import NavLanding from '../Components/NavLanding'
import uniIcon from "./../images/stars-icon.svg"
import arrowSide from "./../images/arrow-side.svg"
import wedoTeam from "./../images/wedo-jlc.jpg"

// USERS
import sUserOne from "./../images/user-small-1.jpg"
import sUserTwo from "./../images/user-small-2.jpg"
import sUserThree from "./../images/user-small-3.jpg"
import sUserFour from "./../images/user-small-4.jpg"
import Form from '../Components/Form'

export default function Landing() {
  return (
    <>
      <NavLanding/>

      {/* HERO */}

      <section className="section landing__home">
        <div className="container">
          <div className="home__uni">
            <img src={uniIcon} alt="" />
            <p>Over <span>500</span> clients</p>
          </div>
          <h1>Find a job that you will love, with more flexibility.<br/> <span>It all starts here.</span></h1>
        </div>
      </section>

      {/* CONTENT */}

      <section className="section cont__sec">
        <div className="container">
          <div className="cont__wrap">

            <h6 className="__header">Our Functionality</h6>

            <h2 className="cont__header">With jobs93, you’ll get have all the tools needed to land your dream job.</h2>

            <div className="columns is-vcentered">

              <div className="column is-12">

                <div className="card__wrap __one">

                  <div className="card__content">
                    <h3>Create a Perfect <br/> Resume</h3>
                    <p>A resume is a one- to two-page document that formalizes and summarizes your work experience, skills and education. Creating a resume that is customized to the job you are applying for is very important. We will make sure your resume is free of grammar mistakes, formatted correctly, and contains the key words needed to make sure your resume will be successful in market.</p>
                    <button className="button">Get started <img src={arrowSide} alt="" /> </button>
                  </div>
                  
                </div>

              </div>

              {/* <div className="column is-12">

                <div className="card__wrap __two">

                  <div className="card__content">
                    <h3>Build up Your References</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas quidem commodi accusantium, explicabo molestias blanditiis?</p>
                    <button className="button">Get started <img src={arrowSide} alt="" /> </button>
                  </div>
                  
                </div>

              </div>

              <div className="column is-3">
                
                <div className="card__wrap __three">

                  <div className="card__content">
                    <h3>Discover New Job Opportunities</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas quidem commodi accusantium, explicabo molestias blanditiis?</p>
                    <button className="button">Get started <img src={arrowSide} alt="" /> </button>
                  </div>
                  
                </div>

              </div> */}

            </div>

          </div>
        </div>
      </section>

      {/* WHAT WE DO */}

      <section className="section wedo__sec">
        <div className="container">
          <div className="wedo__wrap">

            <h6 className="__header">What we do</h6>

            <h2 className="wedo__header">We are on a mission to maximize your job search success.</h2>

            <p className="wedo__p">Finding a new job can be challenging, so we are here to provide you with the tools necessary to successfully land your dream job. We consistently keep us with the latest methods to make sure our clients are able to be successful in Market.</p>

            <img src={wedoTeam} alt="" className="wedo__team" />

          </div>
        </div>
      </section>

      {/* REVIEWS */}

      <section className="section reviews__sec">
        <div className="container">
          <div className="reviews__wrap">

          <h6 className="__header">Reviews & Feedback</h6>
          <h2 className="reviews__header">Feedback from users about jobs93, and how we helped.</h2>

          <div className="columns">
            <div className="column is-5">

              <div className="__test test__small">
                <div className="content__wrap">
                  <div className="user__item">
                    <img src={sUserOne} alt="" />
                    <h4>Susan Franklin</h4>
                  </div>
                  <p>Jobs93 really helped me find my dream job. They were caring and helped me create a resume that got me a lot of interviews</p>
                </div>
              </div>

              <div className="__test test__small __alt">
                <div className="content__wrap">
                  <div className="user__item">
                    <img src={sUserTwo} alt="" />
                    <h4>John Doe</h4>
                  </div>
                  <p>I recommend this website for everyone looking for a job in IT. They will guide you through the process very well!</p>
                </div>
              </div>

            </div>
            <div className="column is-7">
              <div className="__test test__large">
                <div className="__content">
                  <p>Jobs93 really helped me understand how the Market process works and helped me build a good resume that worked very well. I am very excited to start my new job.</p>
                  <h4>- Franklin Thomas</h4>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="columns" style={{marginTop: "1rem"}}>
            <div className="column is-7">
              <div className="__test test__large __alt">
                <div className="__content">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis minus pariatur expedita quia, natus numquam mollitia sint similique est incidunt sequi nam doloremque eum esse illo iure suscipit aut nemo?</p>
                  <h4>- Sarah Joel</h4>
                </div>
              </div>
            </div>
            <div className="column is-5">

              <div className="__test test__small">
                <div className="content__wrap">
                  <div className="user__item">
                    <img src={sUserThree} alt="" />
                    <h4>Samantha Rose</h4>
                  </div>
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis quia nesciunt rem aliquid laborum! Provident vero, totam cupiditate accusamus incidunt recusandae pariatur.</p>
                </div>
              </div>

              <div className="__test test__small __alt">
                <div className="content__wrap">
                  <div className="user__item">
                    <img src={sUserFour} alt="" />
                    <h4>Thomas Doon</h4>
                  </div>
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis quia nesciunt rem aliquid laborum! Provident vero, totam cupiditate accusamus incidunt recusandae pariatur.</p>
                </div>
              </div>

            </div>
          </div> */}

          </div>
        </div>
      </section>

      {/* CONTACT US */}

      <section className="section contact__sec">
        <div className="container">
          <div className="contact__wrap">

            <h6 className="__header">Get in touch</h6>

            <h2 className="contact__header">Have any questions or queries? Send us a message.</h2>

            {/* <p className="contact__p">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis itaque asperiores porro odit ex non?</p> */}

            <Form/>

            

          </div>
        </div>
      </section>

      <section className="__footer">
        <div className="__divider"></div>
        <div className="footer__wrap">
          <p>Copyright jobs93 &copy; All rights reserved</p>
          <p><a href="/">Terms and conditions</a> - <a href="/">Privacy Policy</a></p>
        </div>
      </section>

    </>
  )
}
