import React, {useContext, useState, useEffect, useRef} from 'react'
import NavSide from '../Components/NavSide'
import NavTop from '../Components/NavTop'
import { AuthContext } from "../Context/authContext";
import Messenger from '../Components/Messenger';



export default function Messaging() {

  const {user} = useContext(AuthContext)
  const userId = user.id.toString()
  const chatId = user.id.toString()
  const userName = user.name

  
  return (
    <>
        <NavTop current="mess"/>
        <NavSide current="mess"/>
        <div className="main__section">
            <div className="main__container" style={{maxWidth: "80%"}}>
              <div className="title__wrap">
                  <h2>Help & Advice</h2>
                  <h1>Live Support</h1>
              </div>
                <Messenger userId={userId} chatId={chatId} userName={userName} />
            </div>
        </div>
        
    </>
  )
}
