import React, { useState, useEffect, useContext, useCallback } from 'react';
import NavSide from '../Components/NavSide'
import NavTop from '../Components/NavTop'
import { gql, useQuery, useMutation } from '@apollo/client';
import { AuthContext } from "../Context/authContext";
import editImage from "./../images/edit.svg"
import deleteImage from "./../images/delete.svg"
import saveImage from "./../images/save.svg"
import rollbackImage from "./../images/rollback.svg"
import { NotifContext } from "../Context/notifContext";

const USER_QUERY = gql`
  query GetUser($userId: ID!){
    user(id: $userId) {
        id
        name
        email
        phone
        lastLogin
        type
        isActive
        gender
        batchNumber
    }
  }
`

const EDIT_USER = gql`
    mutation Mutation(
        $userEditInput: UserEditInput
    ) {
        EditUser(
            UserEditInput: $userEditInput
        ) {
            id
            name
            email
        }
    }
`


export default function Account() {

    const {user} = useContext(AuthContext)
    const userId = user.id.toString()
    const { handleNotificationSuccess } = useContext(NotifContext);

    const { loading, error, data } = useQuery(USER_QUERY, {
        variables: {userId},
    });

    const initialState = {
        id: userId,
        email: undefined!==data  ? data.user.email : '',
        name: undefined!==data  ? data.user.name : '',
        phone: undefined!==data  ? data.user.phone : '',
        gender: undefined!==data  ? data.user.gender : '',
        batchNumber: undefined!==data  ? data.user.batchNumber : '',
    }

    const deleteState = {
        id: userId,
    }
    
    const [ input, setInput ] = useState( initialState );
    const [ popup, setPopup ] = useState( false );
    const [ errors, setErrors ] = useState( false );


    useEffect(() => {
        if(loading === false){
          setInput(initialState)
        }
    }, [loading])

    const [ editUser, { loading: newLoading }] = useMutation(EDIT_USER , {
        update(proxy, {data: newData}) {
            window.location.reload(false);
        },
        onCompleted: ()=> handleNotificationSuccess(true),
        onError: ()=> handleNotificationSuccess(false),
        variables: {userEditInput: input}
    })

    const handleOnChange = ( event ) => {
        const newState = { ...input, [event.target.name]: event.target.value };
        setInput( newState );
    };
    const handleFormSubmit = (e) => {
        e.preventDefault()
        editUser()
    }
    const handleCancel = () => {
        setInput(initialState)
        setPopup(false)
    }
    if(loading){
        return <>
        <NavSide current="user"/>
        <div className="loading__wrap__full">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        </>
    }


  return (
    <>
        <NavTop current="acc"/>
        <NavSide />
        <div className="main__section">
            <div className="main__container">
                <div className="title__wrap">
                    <h2>Account Overview</h2>
                    <h1>Your Account</h1>
                </div>
                <div className="content__wrap">
                    <p>Edit or update your account information, to change parameters that are not present please get in touch with Administration.</p>
                </div>
                
                <>
                    {
                        popup === true ?
                            <div className="edit__user__wrap">
                                <div className="edit__user">
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="edit__user__top">
                                            <h3>Editing your information</h3>
                                            <div className="login__item field">
                                                <p>Name</p>
                                                <input type="text" className="input" name="name" onChange={ handleOnChange } placeholder="New name" value={input.name} required/>
                                            </div>
                                            <div className="login__item field">
                                                <p>Gender</p>
                                                <input type="text" className="input" name="gender" onChange={ handleOnChange } placeholder="Their Gender" value={input.gender} required/>
                                            </div>
                                            <div className="login__item field">
                                                <p>Batch Number</p>
                                                <input type="text" className="input" name="batchNumber" onChange={ handleOnChange } placeholder="Batch number" value={input.batchNumber} required/>
                                            </div>
                                            <div className="login__item field">
                                                <p>Email</p>
                                                <input type="text" className="input" name="email" onChange={ handleOnChange } placeholder="New email" value={input.email} required/>
                                            </div>
                                            <div className="login__item field">
                                                <p>Phone</p>
                                                <input type="text" className="input" name="phone" onChange={ handleOnChange } placeholder="New phone" value={input.phone} required/>
                                            </div>
                                        </div>
                                        <div className="edit__user__bottom">
                                            <button className="button" type="submit"><img src={saveImage} alt="" /> Save</button>
                                            <button className="button" type="button" onClick={()=> handleCancel()}><img src={rollbackImage} alt="" /> Close</button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        : ''
                    }
                    <div className="patient__info  __alt">
                        <div className="info__col">
                            <div className="info__item">
                                <h5>Full name</h5>
                                <h3>{data.user.name}</h3>
                            </div>
                        </div>
                        <div className="info__col">
                            <div className="info__item">
                                <h5>Email address</h5>
                                <h4>{data.user.email}</h4>
                            </div>
                            <div className="info__item">
                                <h5>Phone number</h5>
                                <h4>{data.user.phone}</h4>
                            </div>
                        </div>
                        <div className="info__col">
                            <div className="info__item">
                                <h5>Gender</h5>
                                <h4>{data.user.gender}</h4>
                            </div>
                            <div className="info__item">
                                <h5>Batch Number</h5>
                                <h4>{data.user.batchNumber}</h4>
                            </div>
                        </div>
                        <div className="info__col">
                            <div className="info__item">
                                <h5>Last Active</h5>
                                <h4>{data.user.lastLogin}</h4>
                            </div>
                        </div>
                        {/* <div className="info__col">
                            <div className="info__item">
                                <h5>Resume Provided</h5>
                                <h4>{data.user.resInfo === "{}" ? 'False' : 'True'}</h4>
                            </div>
                            <div className="info__item">
                                <h5>References Provided</h5>
                                <h4>{data.user.refInfo === "{}" ? 'False' : 'True'}</h4>
                            </div>
                            <div className="info__item">
                                <h5>Verification Provided</h5>
                                <h4>{data.user.verInfo === "{}" ? 'False' : 'True'}</h4>
                            </div>
                        </div> */}
                    </div>

                

                    <div className="info__del">
                        <div className="del__wrap">
                            <button className="button __edit" onClick={()=> {setInput(initialState);setPopup(true)}}> <img src={editImage} alt="" /> Edit user</button>
                            {/* <button className="button __delete" onClick={()=> handleDelete()}> <img src={deleteImage} alt="" /> Delete user</button> */}
                        </div>
                    </div>
                </>
                
            </div>
        </div>
        
    </>
  )
}
