import React, { useContext } from 'react'
import homeIcon from "./../images/home.svg"
import patientIcon from "./../images/patient.svg"
import analyticsIcon from "./../images/analytics.svg"
import logoutIcon from "./../images/logout.svg"
import infoIcon from "./../images/info.svg"
import helpIcon from "./../images/help-icon.svg"
import userIcon from "./../images/user-icon.svg"
import { AuthContext } from "../Context/authContext";


export default function NavSide(props) {

    const {user, logout} = useContext(AuthContext)

    const current = props.current

    function handleLogout() {
        logout()
        localStorage.removeItem("token")
        window.location.reload(false);
    }
    

  return (
    <nav className="nav__side">
        <div className="side__top">
            <img src="/logo-app-1.svg" alt="" />
            {/* <div className="icon__info">
                <h4>{user.type === "user" ? "Standard User" : "Administrator"}</h4>
                <h5>jobs93</h5>
            </div>
            <a href="/"><button className="button icon__edit">
                <img src={gearIcon} alt="" />
            </button></a> */}
        </div>

        <div className="side__items__top">
            {
                user.type === "user" ?
                <>
                    <a href="/dashboard"><button className={current === "dash" ? "button __active" : "button"}><img src={homeIcon} alt="" /> Dashboard</button></a>
                    <a href="/resume-builder"><button className={current === "res" ? "button __active" : "button"}><img src={patientIcon} alt="" /> Resume Builder</button></a>
                    <a href="/references"><button className={current === "ref" ? "button __active" : "button"}>
                        {/* <div className="notification__new__current">
                            
                        </div> */}
                        <img src={infoIcon} alt="" /> References
                        {/* <div className="notification__new">1</div> */}
                    </button></a>
                    <a href="/verification"><button className={current === "ver" ? "button __active" : "button"}><img src={analyticsIcon} alt="" /> Employment verification</button></a>
                </>
                :
                <>
                    <a href="/dashboard"><button className={current === "dash" ? "button __active" : "button"}><img src={homeIcon} alt="" /> Dashboard</button></a>
                    <a href="/question"><button className={current === "que" ? "button __active" : "button"}><img src={helpIcon} alt="" /> Statistics</button></a>
                    {/* <a href="/users"><button className={current === "user" ? "button __active" : "button"}><img src={userIcon} alt="" /> User management</button></a> */}
                    <a href="/users"><button className={current === "user" ? "button __active" : "button"}>
                        {/* <div className="notification__new__current">
                            
                        </div> */}
                        <img src={userIcon} alt="" /> User management
                        {/* <div className="notification__new">1</div> */}
                    </button></a>
                </>
            }
            
        </div>
        <div className="side__items__top __alt">
            {/* {
                user.type === "user" ?
                <a href="/messaging"><button className={current === "mess" ? "button __active" : "button"}><img src={supportIcon} alt="" /> Live Support</button></a>: ""
            }
             */}
            <button className="button __log" onClick={()=> handleLogout()}><img src={logoutIcon} alt="" /> Sign Out</button>
        </div>
    </nav>
  )
}
