import React, {useContext} from 'react'
import NavSide from '../Components/NavSide'
import NavTop from '../Components/NavTop'
import { gql, useQuery, useMutation } from '@apollo/client';
import { AuthContext } from "../Context/authContext";
import { useNavigate } from 'react-router';

const HOME_QUERY = gql`
  query GetUsers{
    users {
      name
      id
      lastLogin
      type
    }
    resumes {
        id
        userId
        fullName
        batchNumber
    }
    references {
        id
        step
    }
    mailsAll {
        id
        subject
        date
    }
  }
`

export default function Questions() {

    const {user} = useContext(AuthContext)
    let navigate = useNavigate()

    if(user.type !== "admin"){
        navigate('/dashboard')
    }


    const { loading, error, data } = useQuery(HOME_QUERY);

    function perRef(dataM){
        const tempArray = [];
        if (dataM.references.length){
            for(let i = 0; i < dataM.references.length; i++){
                if(dataM.references[i].step === '2'){
                    tempArray.push(true)
                }
            }
        }
        return Math.abs(Math.trunc((100 * tempArray.length) / dataM.references.length) - 100);
    }

    function perRes(dataM){
        return Math.abs(Math.trunc((100 * dataM.resumes.length) / dataM.users.length) - 100);
    }

    if(loading === true){
        return <></>
    }

  return (
    <>
        <NavTop current="que" />
        <NavSide current="que" />
        <div className="main__section">
            <div className="main__container">
                <div className="title__wrap">
                    <h2>Monitoring & Analytics</h2>
                    <h1>User Statistics</h1>
                </div>
                <div className="content__wrap">
                    <p>Find out how users are engagin with the application, thier prefered usage, and missing data they have provided.</p>
                </div>
                <div className="dash__section">
                        <h4> User Engagement</h4>
                        <div className="__border"></div>
                        <div className="dash__items__wrap">
                            <div className="dash__item ref__one">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNLjAwMiAyMGg2LjAwMWMtLjAyOC02LjU0MiAyLjk5NS0zLjY5NyAyLjk5NS04LjkwMSAwLTIuMDA5LTEuMzExLTMuMDk5LTIuOTk4LTMuMDk5LTIuNDkyIDAtNC4yMjYgMi4zODMtMS44NjYgNi44MzkuNzc1IDEuNDY0LS44MjUgMS44MTItMi41NDUgMi4yMDktMS40OS4zNDQtMS41ODkgMS4wNzItMS41ODkgMi4zMzNsLjAwMi42MTl6bTIwLjQ5OC03Yy0xLjkzMiAwLTMuNSAxLjU2Ny0zLjUgMy41czEuNTY4IDMuNSAzLjUgMy41IDMuNS0xLjU2NyAzLjUtMy41LTEuNTY4LTMuNS0zLjUtMy41em0xLjUgNGgtMXYxaC0xdi0xaC0xdi0xaDF2LTFoMXYxaDF2MXptLTQuODE0IDNoLTkuMTgzbC0uMDAzLS44MjljMC0xLjY3OS4xMzMtMi42NDkgMi4xMTgtMy4xMDcgMi4yNDMtLjUxOCA0LjQ1OC0uOTgxIDMuMzk0LTIuOTQ1LTMuMTU2LTUuODItLjkwMS05LjExOSAyLjQ4OC05LjExOSA0LjA2IDAgNC44NTcgNC4xMTkgMy4wODUgNy45MDMtMS45NzIuNjA5LTMuNDE5IDIuNDI4LTMuNDE5IDQuNTk3IDAgMS4zOC41ODkgMi42MTkgMS41MiAzLjV6Ii8+PC9zdmc+" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                <h5><span>{data.users.length}</span> new users this month</h5>
                                <p>There are currently a total of <span>{data.users.length}</span> user accounts, present in the application.</p>
                            </div>

                            <div className="dash__item ref__two">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xOC43OTkgNy4wMzhjLS40OTYtLjUzNS0uNzk5LTEuMjUyLS43OTktMi4wMzggMC0xLjY1NiAxLjM0NC0zIDMtM3MzIDEuMzQ0IDMgMy0xLjM0NCAzLTMgM2MtLjE0NiAwLS4yOS0uMDEtLjQzMS0uMDMxbC0zLjMzMyA2LjAzMmMuNDc1LjUzLjc2NCAxLjIzMS43NjQgMS45OTkgMCAxLjY1Ni0xLjM0NCAzLTMgM3MtMy0xLjM0NC0zLTNjMC0uNTgzLjE2Ny0xLjEyNy40NTUtMS41ODdsLTIuNTY1LTMuNTQ3Yy0uMjgxLjA4Ny0uNTguMTM0LS44OS4xMzRsLS4zNjgtLjAyMi0zLjM1NSA2LjA2OWMuNDUxLjUyNS43MjMgMS4yMDguNzIzIDEuOTUzIDAgMS42NTYtMS4zNDQgMy0zIDNzLTMtMS4zNDQtMy0zIDEuMzQ0LTMgMy0zYy4xODYgMCAuMzY3LjAxNy41NDMuMDQ5bDMuMjk4LTUuOTY3Yy0uNTItLjUzOS0uODQxLTEuMjczLS44NDEtMi4wODIgMC0xLjY1NiAxLjM0NC0zIDMtM3MzIDEuMzQ0IDMgM2MwIC42MTctLjE4NyAxLjE5MS0uNTA3IDEuNjY5bDIuNTI3IDMuNDk1Yy4zMDctLjEwNi42MzctLjE2NC45OC0uMTY0LjE2NCAwIC4zMjUuMDEzLjQ4Mi4wMzlsMy4zMTctNi4wMDF6bS0zLjc5OSA3Ljk2MmMuNTUyIDAgMSAuNDQ4IDEgMXMtLjQ0OCAxLTEgMS0xLS40NDgtMS0xIC40NDgtMSAxLTF6bS02LThjLjU1MiAwIDEgLjQ0OCAxIDFzLS40NDggMS0xIDEtMS0uNDQ4LTEtMSAuNDQ4LTEgMS0xeiIvPjwvc3ZnPg==" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                <h5><span>100%</span> of users are active</h5>
                                <p><span>100%</span> of users have logged into the application this month. <a href="/question">Learn more</a>.</p>
                            </div>
                        </div>
                        
                </div>
                <div className="content__wrap">
                    <p className="__wip">
                        This is currently a <span>Work in progress</span>
                    </p>
                </div>
        </div>
        </div>
        
    </>
  )
}
