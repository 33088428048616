import dlexImage from "./../images/icon-dark-bg.svg"
import check from "./../images/check-circle.svg"
import { useState, useContext, useEffect } from 'react';
import { AuthContext } from "../Context/authContext";
import { useMutation, gql } from "@apollo/client";
import { useNavigate } from "react-router";

const REGISTER_USER = gql`
    mutation Mutation(
        $registerInput: RegisterInput
    ) {
        registerUser(
            registerInput: $registerInput
        ) {
            id
            email
            lastLogin
            type
            token
        }
    }
`

export default function Register(props) {

    const context = useContext(AuthContext)
    let navigate = useNavigate
    const [errors, setErrors] = useState({});

    const initialState = {
        firstname: '',
        lastname: '',
        password: '',
        email: '',
        phone: ''
    }

    const [ input, setInput ] = useState( initialState );
    const [ newInput, setNewInput ] = useState( {} );


    // console.log('ERRORS', errors)

    const [ registerUser, {loading}] = useMutation(REGISTER_USER, {
        update(proxy, {data: {registerUser: userData}}) {
            context.login(userData)
            window.location.reload(false);
        },
        onError({graphQLErrors}) {
            setErrors(graphQLErrors)
        },
        variables: {registerInput: newInput}
    })
  
  
  const handleFormSubmit = (e) => {
    e.preventDefault()
    setNewInput({
        name: input.firstname + " " + input.lastname,
        password: input.password,
        email: input.email,
        phone: input.phone,
        type: "user"
    })
  }

 useEffect(() => {
    if(newInput !== {}){
        registerUser()
    }
    
 }, [newInput])
 

// console.log('MAIN STATE', newInput)

  const handleOnChange = ( event ) => {
    const newState = { ...input, [event.target.name]: event.target.value };
    setInput( newState );
  };

//   console.log('INPUT', input)


  return (
    <div className="login__wrap login__uni __register">
        <div className="login__side ">
            <div className="login__cont">
                <img className="login__image" src={dlexImage} alt=""/>
                <h1>Register for a jobs93 account</h1>

                <a href="/login"><button className="button register__new" type="button">Already have an account? <span>Sign in</span></button></a>
                
                <div className="register__form__wrap">
                <form className="register__form" onSubmit={handleFormSubmit}>

                    {
                      errors.length ?
                      <div className="register__error">
                        <p>{errors[0].message}</p>
                      </div>
                      : ""
                    }

                    <h4>Step 01</h4>
                    <h3>Fill out your personal information</h3>
                    <div className="columns is-vcentered">
                        <div className="column is-6">
                            <div className="login__item field">
                                <input type="text" className="input" name="firstname" onChange={ handleOnChange } placeholder="Your first name"/>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="login__item field">
                                <input type="text" className="input" name="lastname" onChange={ handleOnChange } placeholder="Your last name"/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="login__item field">
                        <input type="email" className="input" name="email" onChange={ handleOnChange } placeholder="Your email address"/>
                    </div>

                    <div className="login__item field">
                        <input type="password" className="input" name="password" onChange={ handleOnChange } placeholder="Select a password"/>
                    </div>

                    <h4 className="__alt">Step 02</h4>
                    <h3>Provide some additional information</h3>

                    <div className="login__item field">
                        <input type="text" className="input" name="phone" onChange={ handleOnChange } placeholder="Your phone number"/>
                    </div>

                    {/* <div className="login__item field">
                        <input type="text" className="input" name="officeAddress" onChange={ handleOnChange } placeholder="Desired job title"/>
                    </div>

                    <div className="login__item field">
                        <input type="text" className="input" name="officeId" onChange={ handleOnChange } placeholder="Second desired job title"/>
                    </div> */}

                    {/* <h3>What is your ideal company size</h3> */}

                    <div className="register__button__wrap">
                        {/* <div className="columns is-vcentered">
                            <div className="column is-4">
                                <button className="button button__number __active">
                                    <img src={person1} alt="" />
                                    <p>1 - 25 people</p>
                                </button>
                            </div>
                            <div className="column is-4">
                                <button className="button button__number">
                                    <img src={person2} alt="" />
                                    <p>25 - 75 people</p>
                                </button>
                            </div>
                            <div className="column is-4">
                                <button className="button button__number">
                                    <img src={person3} alt="" />
                                    <p>75+ people</p>
                                </button>
                            </div>
                        </div> */}
                        
                    </div>
                    

                    
                    
                    <div className="submit__wrap">
                        <button type="submit" className="button register__submit">Sign in <img src={check} alt="" /></button>
                    </div>
                    
                  </form>
                  </div>
            </div>
        </div>
    </div>
  )
}
