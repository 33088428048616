import React, {useContext, useState, useEffect, useCallback} from 'react'
import NavSide from '../Components/NavSide'
import NavTop from '../Components/NavTop'
import { AuthContext } from "../Context/authContext";
import { NotifContext } from "../Context/notifContext";
import { useMutation, gql, useQuery } from "@apollo/client";
import Mailer from '../Components/Mailer';
import { useNavigate } from 'react-router';

const CREATE_RESUME = gql`
    mutation CreateResume($createResumeInput: CreateResumeInput) {
        CreateResume(createResumeInput: $createResumeInput)
    }
`;

const EDIT_RESUME = gql`
    mutation EditResume($editResumeInput: EditResumeInput) {
        EditResume(editResumeInput: $editResumeInput)
    }
`;

const RESUME_QUERY = gql`
query Resume($userId: String!) {
  resume(userId: $userId) {
    id
    userId
    fullName
    batchNumber
    marketGroup
    country
    city
  }
}
`

export default function Resume() {

    const {user} = useContext(AuthContext)
    const userId = user.id.toString()
    const chatId = user.id.toString() + "ref"
    const instanceId = user.id.toString() + "ref"
    const userName = user.name
    const {handleNotificationSuccess} = useContext(NotifContext)

    let navigate = useNavigate()

    useEffect(() => {
        if(user.type !== "user"){
            navigate('/dashboard')
        }
    }, [])

    const { loading, error, data, refetch } = useQuery(RESUME_QUERY, {
        variables: {userId},
    });

    const [ editResume, { loading: loadingA, error: errorA, data: dataA }] = useMutation(EDIT_RESUME, {
        onCompleted: ()=> {refetch(); handleNotificationSuccess(true)},
        onError: ()=> handleNotificationSuccess(false)
    });

    const [ createResume, { loading: loadingB, error: errorB, data: dataB }] = useMutation(CREATE_RESUME, {
        onCompleted: ()=> {refetch(); handleNotificationSuccess(true)},
        onError: ()=> handleNotificationSuccess(false)
    });

    const initialState =  {
        userId: userId,
        fullName: undefined !== data ? data.resume.length ? data.resume[0].fullName : '' : '',
        batchNumber: undefined !== data ? data.resume.length ? data.resume[0].batchNumber : '' : '',
        marketGroup: undefined !== data ? data.resume.length ? data.resume[0].marketGroup : '' : '',
        country: undefined !== data ? data.resume.length ? data.resume[0].country : '' : '',
        city: undefined !== data ? data.resume.length ? data.resume[0].city : '' : ''
    }

    const [ input, setInput ] = useState( initialState );



    useEffect(() => {
        if(loading === false){
          if(data.resume.length){
            setInput(initialState)
          }
        }
    }, [loading])

    const handleFormSubmit = (e) => {
        e.preventDefault()
        // console.log("INPUT", input)
        if(data.resume.length){
            const editResumeInput = input
            editResume({ variables: {editResumeInput} });
        } else {
            const createResumeInput = input
            createResume({ variables: {createResumeInput} });
        }
    }

    const handleOnChange = ( event ) => {
        const newState = { ...input,  [event.target.name]: event.target.value };
        setInput( newState );
    };

    // console.log("DATA", data)

    if(loading){
        return <></>
    }


  return (
    <>
        <NavTop current="res"/>
        <NavSide current="res"/>
        <div className="main__section">
            <div className="main__container">
                <div className="title__wrap">
                    <h2>Build your CV</h2>
                    <h1>Resume Builder</h1>
                </div>
                <div className="content__wrap">
                    <p>Get your resume started right with Jobs93, go through our guided process with quality control and real feedback on your progress.</p>
                </div>

                <Mailer userId = {userId}/>

                <div className="resume__question__wrap">

                    <h3 style={{marginTop: "-1rem"}}><b>Answer the following questions</b></h3>
                    <p>Make sure to answer the following and save the form so an Admin can get back to you.</p>
                    {
                        data.resume.length ? 
                        <>
                        <div className="resume__question">
                            <h4>Question 1</h4>
                            <p className="p__question">Your Full Name</p>
                            {
                                data.resume[0].fullName !== "" ?
                                <p><b>{input.fullName}</b></p>
                                :
                                <input type="text" className="input" name="fullName" placeholder="Enter your full name" value={input.fullName} onChange={ handleOnChange } required/>
                            }
                            
                            
                            {/* value={input[question.number - 1].value}  */}
                        </div>
                        <div className="resume__question">
                            <h4>Question 2</h4>
                            <p className="p__question">Batch Number</p>
                            {
                                data.resume[0].fullName !== "" ?
                                <p><b>{input.batchNumber}</b></p>
                                :
                                <input type="text" className="input" name="batchNumber" placeholder="Enter your Batch Number" value={input.batchNumber} onChange={ handleOnChange } required/>
                            }
                            {/* value={input[question.number - 1].value} onChange={ handleOnChange } */}
                        </div>
                        <div className="resume__question">
                            <h4>Question 3</h4>
                            <p className="p__question">Market Group Name</p>
                            {
                                data.resume[0].fullName !== "" ?
                                <p><b>{input.marketGroup}</b></p>
                                :
                                <input type="text" className="input" name="marketGroup" placeholder="Enter your Market Group Name" value={input.marketGroup} onChange={ handleOnChange } required/>
                            }
                            {/* value={input[question.number - 1].value} onChange={ handleOnChange } */}
                        </div>
                        <div className="resume__question">
                            <h4>Question 4</h4>
                            <p className="p__question">Your Country</p>
                            {
                                data.resume[0].fullName !== "" ?
                                <p><b>{input.country}</b></p>
                                :
                                <input type="text" className="input" name="country" placeholder="Enter your Country" value={input.country} onChange={ handleOnChange } required/>
                            }
                            {/* value={input[question.number - 1].value} onChange={ handleOnChange } */}
                        </div>
                        <div className="resume__question">
                            <h4>Question 5</h4>
                            <p className="p__question">Your City</p>
                            {
                                data.resume[0].fullName !== "" ?
                                <p><b>{input.city}</b></p>
                                :
                                <input type="text" className="input" name="city" placeholder="Enter your City" value={input.city} onChange={ handleOnChange } required/>
                            }
                            {/* value={input[question.number - 1].value} onChange={ handleOnChange } */}
                        </div>
                        </>
                        :
                        <>
                        <form onSubmit={handleFormSubmit}>
                        <div className="resume__question">
                            <h4>Question 1</h4>
                            <p className="p__question">Your Full Name</p>
                            <input type="text" className="input" name="fullName" placeholder="Enter your full name" value={input.fullName} onChange={ handleOnChange } required/>
                        </div>
                        <div className="resume__question">
                            <h4>Question 2</h4>
                            <p className="p__question">Batch Number</p>
                            <input type="text" className="input" name="batchNumber" placeholder="Enter your Batch Number" value={input.batchNumber} onChange={ handleOnChange } required/>
                        </div>
                        <div className="resume__question">
                            <h4>Question 3</h4>
                            <p className="p__question">Market Group Name</p>
                            <input type="text" className="input" name="marketGroup" placeholder="Enter your Market Group Name" value={input.marketGroup} onChange={ handleOnChange } required/>
                        </div>
                        <div className="resume__question">
                            <h4>Question 4</h4>
                            <p className="p__question">Your Country</p>
                            <input type="text" className="input" name="country" placeholder="Enter your Country" value={input.country} onChange={ handleOnChange } required/>
                        </div>
                        <div className="resume__question">
                            <h4>Question 5</h4>
                            <p className="p__question">Your City</p>
                            <input type="text" className="input" name="city" placeholder="Enter your City" value={input.city} onChange={ handleOnChange } required/>
                        </div>
                        <button className="button res__finish" type="submit">Save Changes</button>
                        </form>
                        </>
                    }
                </div>

                
            </div>
        </div>
        
    </>
  )
}
