import React, { useState, useCallback, useContext, useEffect } from 'react';
import exportIcon from './../images/export.svg';
import fileIcon from './../images/file-uploader.svg';
import pinPlus from './../images/pin-correct.svg';
import pinNone from './../images/pin-incorrect.svg';
import pinPlusAlt from './../images/pin-correct-alt.svg';
import pinNoneAlt from './../images/pin-incorrect-alt.svg';
import chevron from './../images/chevron-full.svg';
import { Editor } from 'react-draft-wysiwyg';
import { useDropzone } from 'react-dropzone';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useMutation, gql, useQuery } from '@apollo/client';
import { AuthContext } from '../Context/authContext';
import { convertToRaw } from 'draft-js';
import { NotifContext } from '../Context/notifContext';
import draftToHtml from 'draftjs-to-html';
import { saveAs } from 'file-saver';

const MAIL_QUERY = gql`
  query MAIL_QUERY($userId: ID!) {
    mails(userId: $userId) {
      id
      subject
      message
      date
      userName
      userId
      link
    }
  }
`;
const MAIL_SUBSCRIPTION = gql`
  subscription Subscription($userId: ID!) {
    mails(userId: $userId) {
      id
      subject
      message
      date
      userName
      userId
      link
    }
  }
`;

const CREATE_MAIL = gql`
  mutation MailUpload(
    $file: [Upload]!
    $userId: String!
    $userName: String!
    $subject: String!
    $message: String!
  ) {
    mailUpload(
      file: $file
      userId: $userId
      userName: $userName
      subject: $subject
      message: $message
    )
  }
`;

const CREATE_MAIL_NO_FILE = gql`
  mutation mailUploadNoFile(
    $userId: String!
    $userName: String!
    $subject: String!
    $message: String!
  ) {
    mailUploadNoFile(
      userId: $userId
      userName: $userName
      subject: $subject
      message: $message
    )
  }
`;

export default function Mailer(props) {
  const [mailer, setMailer] = useState(0);
  const [newMail, setNewMail] = useState(false);
  const [editorState, setEditorState] = useState('');
  const [content, setContent] = useState('');
  const [subjectInput, setSubjectInput] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [mails, setMails] = useState([]);
  const { user } = useContext(AuthContext);
  const { handleNotificationSuccess } = useContext(NotifContext);
  const [activeMails, setActiveMails] = useState([]);

  const { userId } = props;

  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    MAIL_QUERY,
    {
      variables: { userId },
    }
  );
  function iterateMailer(mailerValue) {
    // console.log("SOMEONE CALLED ME")
    setMailer(mailerValue + 1);
  }

  subscribeToMore({
    document: MAIL_SUBSCRIPTION,
    variables: { userId },
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev;
      const newFeedItem = subscriptionData.data.mails;
      const newArray = subscriptionData.data.mails.slice();
      const mailerValue = mailer;
      if (mailerValue !== 9999 && mailerValue !== 0) {
        iterateMailer(mailerValue);
      }
      setMails(newArray.sort((a, b) => b.id - a.id));
      return Object.assign({}, prev, {
        files: { ...prev.mails, newFeedItem },
      });
    },
  });

  // console.log("MAILER", mailer)

  useEffect(() => {
    if (loading === false) {
      if (data.mails.length) {
        const newArray = data.mails.slice();
        setMails(newArray.sort((a, b) => b.id - a.id));
      }
    }
  }, [data]);

  const [createMail, { loading: loadingA, error: errorA, data: dataA }] =
    useMutation(CREATE_MAIL, {
      onCompleted: () => {
        refetch();
        window.location.reload(false);
        setCurrentFile(null);
        setEditorState('');
        handleNotificationSuccess(true);
      },
      onError: () => handleNotificationSuccess(false),
    });

  const [createMailNoFile, { loading: loadingB, error: errorB, data: dataB }] =
    useMutation(CREATE_MAIL_NO_FILE, {
      onCompleted: () => {
        refetch();
        window.location.reload(false);
        setCurrentFile(null);
        setEditorState('');
        handleNotificationSuccess(true);
      },
      onError: () => handleNotificationSuccess(false),
    });

  const onDrop = useCallback(
    ([file]) => {
      setCurrentFile(file);
    },
    [createMail]
  );

  function handleFormSubmit(e) {
    e.preventDefault();
    if (currentFile !== null) {
      const mailFields = {
        file: currentFile,
        userId: userId,
        userName: user.name,
        subject: subjectInput,
        message: JSON.stringify(content),
      };
      createMail({ variables: mailFields });
    } else {
      const mailFields = {
        userId: userId,
        userName: user.name,
        subject: subjectInput,
        message: JSON.stringify(content),
      };
      createMailNoFile({ variables: mailFields });
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function downloadFile(link) {
    saveAs(link, link.split('/')[3]);
  }

  function getDate(time) {
    const date = new Date(time);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    const currentDate = mm + '/' + dd + '/' + yyyy;
    const hourCurrent =
      date.getHours() > 12
        ? (date.getHours() - 12).toString()
        : date.getHours().toString();
    const minuteCurrent = ('0' + date.getMinutes()).slice(-2);
    const designation = date.getHours() > 12 ? 'pm' : 'am';
    const timeCurrent = hourCurrent + ':' + minuteCurrent + designation;

    return currentDate;
  }

  function getTime(time) {
    const date = new Date(time);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    const currentDate = mm + '/' + dd + '/' + yyyy;
    const hourCurrent =
      date.getHours() > 12
        ? (date.getHours() - 12).toString()
        : date.getHours().toString();
    const minuteCurrent = ('0' + date.getMinutes()).slice(-2);
    const designation = date.getHours() > 12 ? 'pm' : 'am';
    const timeCurrent = hourCurrent + ':' + minuteCurrent + designation;

    return timeCurrent;
  }

  // console.log('MAILER', activeMails);

  if (loading === true) {
    return <></>;
  }

  return (
    <div className="mail__wrap">
      <div className="mail__header">
        <p>Resume Inbox</p>
      </div>
      <div className="mail__key">
        <div className="mail__controls">
          {newMail !== true ? (
            <button className="button __altb" onClick={() => setNewMail(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" />
              </svg>
              New message
            </button>
          ) : (
            <button
              className="button __altb __active"
              onClick={() => setNewMail(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M19.885 5.515c-4.617-4.618-12.056-4.676-16.756-.195l-2.129-2.258v7.938h7.484l-2.066-2.191c2.82-2.706 7.297-2.676 10.074.1 2.992 2.993 2.664 7.684-.188 10.319l3.314 3.5c4.716-4.226 5.257-12.223.267-17.213z" />
              </svg>{' '}
              Cancel Creation
            </button>
          )}
          {newMail === true ? (
            <div className="mail__single">
              {/* <h3 className="cont__text">Send a message</h3> */}
              <form onSubmit={handleFormSubmit}>
                <h5 className="cont__text">Subject</h5>
                <div className="" style={{ maxWidth: '80%' }}>
                  <input
                    type="text"
                    className="input"
                    placeholder="A summary of the message"
                    required
                    onChange={(e) => setSubjectInput(e.target.value)}
                  />
                </div>
                <h4 className="__uni cont__text">Message</h4>
                <div
                  className=""
                  style={{
                    maxWidth: '100%',
                    backgroundColor: '#fff',
                    minHeight: '300px',
                  }}
                >
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(newState) => {
                      setEditorState(newState);
                      setContent(
                        draftToHtml(convertToRaw(newState.getCurrentContent()))
                      );
                    }}
                  />
                </div>
                <h4 className="__uni cont__text">Attatchments</h4>
                {currentFile === null ? (
                  <div className="uploader__input">
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <p>Click to select files</p>
                    </div>
                  </div>
                ) : (
                  <div className="uploader__item" style={{ marginTop: '2rem' }}>
                    <div className="__left">
                      <div className="img__wrap">
                        <img src={fileIcon} alt="" />
                      </div>
                      <h5>{currentFile.name}</h5>
                      <h6>
                        <span>{currentFile.type}</span>
                      </h6>
                    </div>
                  </div>
                )}

                <button className="button __send" type="submit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19.885 5.515c-4.617-4.618-12.056-4.676-16.756-.195l-2.129-2.258v7.938h7.484l-2.066-2.191c2.82-2.706 7.297-2.676 10.074.1 2.992 2.993 2.664 7.684-.188 10.319l3.314 3.5c4.716-4.226 5.257-12.223.267-17.213z" />
                  </svg>{' '}
                  Send message
                </button>
              </form>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className="columns is-vcentered is-gapless __key">
          <div className="column is-7">
            <p>Subject</p>
          </div>
          <div className="column is-2">
            <p>From</p>
          </div>
          <div className="column is-3">
            <p>Date/Time sent</p>
          </div>
        </div>
      </div>

      <div className="mail__main">
        {mails.length > 0 ? (
          mails.map((mail, i) => (
            <>
              <div
                className={
                  activeMails.includes(mail.id)
                    ? 'mail__item __active'
                    : 'mail__item'
                }
                key={mail.id}
                onClick={
                  activeMails.includes(mail.id)
                    ? () =>
                        setActiveMails(activeMails.filter((a) => a !== mail.id))
                    : () => setActiveMails([...activeMails, mail.id])
                }
              >
                <div className="columns">
                  <div className="column is-7">
                    <div className="flex__mail">
                      {mail.link === '' ? (
                        activeMails.includes(mail.id) ? (
                          <img src={pinNoneAlt} alt="" />
                        ) : (
                          <img src={pinNone} alt="" />
                        )
                      ) : activeMails.includes(mail.id) ? (
                        <img src={pinPlusAlt} alt="" />
                      ) : (
                        <img src={pinPlus} alt="" />
                      )}

                      <p className="__uni">{mail.subject}</p>
                    </div>
                  </div>
                  <div className="column is-2">
                    <p>{mail.userName === user.name ? 'You' : mail.userName}</p>
                  </div>

                  <div className="column is-3">
                    <div className="flex__mail__alt">
                      <p>
                        {getTime(mail.date)} - {getDate(mail.date)}
                      </p>
                      <img src={chevron} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {activeMails.includes(mail.id) ? (
                <div className="mail__single __pop">
                  <h3 className="cont__text">{mail.subject}</h3>
                  <h5 className="cont__text">
                    From {mail.userName === user.name ? 'You' : mail.userName}
                  </h5>
                  <h5
                    className="cont__text text__alt"
                    style={{ marginBottom: '0.25rem' }}
                  >
                    Time sent {getTime(mail.date)}
                  </h5>
                  <h5 className="cont__text text__alt">
                    Date sent {getDate(mail.date)}
                  </h5>
                  {/* <h4 className="cont__text">Message</h4> */}

                  <div
                    className="wysiwyg__text"
                    dangerouslySetInnerHTML={{
                      __html: JSON.parse(mail.message),
                    }}
                  />

                  <h5 className="cont__text text__alt">Attatchments</h5>
                  {mail.link === '' ? (
                    <div className="mail__no__attachment">
                      <img
                        src="data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTIuMDAyIDIuMDA1YzUuNTE4IDAgOS45OTggNC40OCA5Ljk5OCA5Ljk5NyAwIDUuNTE4LTQuNDggOS45OTgtOS45OTggOS45OTgtNS41MTcgMC05Ljk5Ny00LjQ4LTkuOTk3LTkuOTk4IDAtNS41MTcgNC40OC05Ljk5NyA5Ljk5Ny05Ljk5N3ptMCA4LjkzMy0yLjcyMS0yLjcyMmMtLjE0Ni0uMTQ2LS4zMzktLjIxOS0uNTMxLS4yMTktLjQwNCAwLS43NS4zMjQtLjc1Ljc0OSAwIC4xOTMuMDczLjM4NC4yMTkuNTMxbDIuNzIyIDIuNzIyLTIuNzI4IDIuNzI4Yy0uMTQ3LjE0Ny0uMjIuMzQtLjIyLjUzMSAwIC40MjcuMzUuNzUuNzUxLjc1LjE5MiAwIC4zODQtLjA3My41My0uMjE5bDIuNzI4LTIuNzI4IDIuNzI5IDIuNzI4Yy4xNDYuMTQ2LjMzOC4yMTkuNTMuMjE5LjQwMSAwIC43NS0uMzIzLjc1LS43NSAwLS4xOTEtLjA3My0uMzg0LS4yMi0uNTMxbC0yLjcyNy0yLjcyOCAyLjcxNy0yLjcxN2MuMTQ2LS4xNDcuMjE5LS4zMzguMjE5LS41MzEgMC0uNDI1LS4zNDYtLjc1LS43NS0uNzUtLjE5MiAwLS4zODUuMDczLS41MzEuMjJ6IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4="
                        alt=""
                      />
                      <p>No file was attatched</p>
                    </div>
                  ) : (
                    <div className="uploader__item">
                      <div className="__left">
                        <div className="img__wrap">
                          <img src={fileIcon} alt="" />
                        </div>
                        <h5>{mail.link.split('/')[3].split('.')[0]}</h5>
                        <h6>
                          <span>{mail.link.split('/')[3].split('.')[1]}</span>
                        </h6>
                      </div>
                      <div className="__right">
                        <button
                          className="button"
                          onClick={() => downloadFile(mail.link)}
                        >
                          <img src={exportIcon} alt="" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </>
          ))
        ) : (
          <p className="__nothing">No emails found, start by sending one!</p>
        )}
      </div>
    </div>
  );
}
