import React, { useState, useEffect, useContext } from 'react';
import NavSide from '../Components/NavSide';
import NavTop from '../Components/NavTop';
import editImage from './../images/edit.svg';
import deleteImage from './../images/delete.svg';
import saveImage from './../images/save.svg';
import rollbackImage from './../images/rollback.svg';
import exportIcon from './../images/export.svg';
import removeNotIcon from './../images/remove-not.svg';
import { AuthContext } from '../Context/authContext';
import Uploader from '../Components/Uploader';
import 'react-datepicker/dist/react-datepicker.css';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Messenger from '../Components/Messenger';
import ResumeAdmin from '../Components/ResumeAdmin';
import RefrencesAdmin from '../Components/RefrencesAdmin';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Mailer from '../Components/Mailer';
import { NotifContext } from '../Context/notifContext';

const USER_QUERY = gql`
  query GetUser($userId: ID!) {
    user(id: $userId) {
      id
      name
      email
      phone
      lastLogin
      type
      isActive
      gender
      batchNumber
      resNotUser
      refNotUser
      verNotUser
    }
  }
`;
const USER_NOT_SUBSCRIPTION = gql`
  subscription UserNotSingle($userId: ID!) {
    userNotSingle(userId: $userId) {
      id
      type
    }
  }
`;

const EDIT_USER = gql`
  mutation Mutation($userEditInput: UserEditInput) {
    EditUser(UserEditInput: $userEditInput) {
      id
      name
      email
    }
  }
`;

const DELETE_USER = gql`
  mutation Mutation($userDeleteInput: UserDeleteInput) {
    DeleteUser(UserDeleteInput: $userDeleteInput)
  }
`;

const REMOVE_NOTIFICATION = gql`
  mutation Mutation($userId: String!, $type: String!) {
    RemoveNotification(userId: $userId, type: $type)
  }
`;

export default function User() {
  const { user } = useContext(AuthContext);
  let navigate = useNavigate();

  // const [currentActive, setCurrentActive] = useState(4);

  let [searchParams, setSearchParams] = useSearchParams();

  const currentActive = searchParams.get('current');

  if (user.type !== 'admin') {
    navigate('/dashboard');
  }

  let { id } = useParams();

  const userId = id.toString();

  const verChatId = userId + 'ver';
  const verInstanceId = userId + 'ver';

  const { subscribeToMore, loading, error, data, refetch } = useQuery(
    USER_QUERY,
    {
      variables: { userId },
    }
  );

  subscribeToMore({
    document: USER_NOT_SUBSCRIPTION,
    variables: { userId },
    updateQuery: () => {
      //   console.log("WE MADE IT HERE")
      refetch();
    },
  });

  const [
    RemoveNotification,
    { loading: loadingA, error: errorA, data: dataA },
  ] = useMutation(REMOVE_NOTIFICATION, {
    onCompleted: () => refetch(),
  });

  const adminId = user.id.toString();
  const adminName = user.name;

  const initialState = {
    id: id.toString(),
    email: undefined !== data ? data.user.email : '',
    name: undefined !== data ? data.user.name : '',
    phone: undefined !== data ? data.user.phone : '',
    gender: undefined !== data ? data.user.gender : '',
    batchNumber: undefined !== data ? data.user.batchNumber : '',
  };

  const deleteState = {
    id: userId,
  };

  const [input, setInput] = useState(initialState);
  const [popup, setPopup] = useState(false);
  const { handleNotificationSuccess } = useContext(NotifContext);

  useEffect(() => {
    if (loading === false) {
      setInput(initialState);
    }
  }, [loading]);

  const [editUser, { loading: newLoading }] = useMutation(EDIT_USER, {
    update(proxy, { data: newData }) {
      window.location.reload(false);
    },
    onCompleted: () => {
      handleNotificationSuccess(true);
    },
    onError: () => handleNotificationSuccess(false),
    variables: { userEditInput: input },
  });

  const [deleteUser, { loading: deleteLoading }] = useMutation(DELETE_USER, {
    update(proxy, { data: deleteData }) {
      handleNotificationSuccess(true);
      navigate('/users');
      window.location.reload(false);
    },
    onError() {
      handleNotificationSuccess(false);
    },
    variables: { userDeleteInput: deleteState },
  });

  const handleOnChange = (event) => {
    const newState = { ...input, [event.target.name]: event.target.value };
    setInput(newState);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    editUser();
  };
  const handleCancel = () => {
    setInput(initialState);
    setPopup(false);
  };
  const handleDelete = () => {
    deleteUser();
  };
  if (loading) {
    return (
      <>
        <NavSide current="user" />
        <div className="loading__wrap__full">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }

  // console.log("DATA", data)

  return (
    <>
      <NavTop current="pat-fe" user={data.user.name} />
      <NavSide current="user" />
      <div className="main__section">
        <div className="main__container __alt">
          <div className="title__wrap __alt">
            <h2>User information</h2>
            <div className="title__patient">
              <h1>{data.user.name}</h1>
              <button
                className="button __edit __alt"
                style={{ marginLeft: '1rem' }}
              >
                {' '}
                <img src={exportIcon} alt="" /> Export
              </button>
            </div>
          </div>

          <div className="patient__nav">
            <button
              className={
                currentActive === 'user' ? 'button __active' : 'button'
              }
              onClick={() => setSearchParams({ current: 'user' })}
            >
              User information
            </button>

            <button
              className={
                currentActive === 'resume' ? 'button __active' : 'button'
              }
              onClick={() => setSearchParams({ current: 'resume' })}
            >
              {data.user.resNotUser ? <div className="has__update"></div> : ''}
              Resume Builder
            </button>

            <button
              className={
                currentActive === 'references' ? 'button __active' : 'button'
              }
              onClick={() => setSearchParams({ current: 'references' })}
            >
              {data.user.refNotUser ? <div className="has__update"></div> : ''}
              References
            </button>

            <button
              className={
                currentActive === 'verification' ? 'button __active' : 'button'
              }
              onClick={() => setSearchParams({ current: 'verification' })}
            >
              {data.user.verNotUser ? <div className="has__update"></div> : ''}
              Employment verification
            </button>

            {/* <button className={currentActive === 5 ? "button __active" : "button"} onClick={()=> setCurrentActive(5)}>Support</button> */}
          </div>

          {
            // MESSENGER
            // currentActive === 5 ?
            // <div className="messenger__admin__wrap" style={{maxWidth: "80%"}}>
            //     <Messenger userId={adminId} chatId={userId} userName={adminName} />
            // </div>
            // :

            // EMPLOYMENT VERIFICATION
            currentActive === 'verification' ? (
              <>
                <div className="user__rundown__wrap">
                  <button
                    className="button user__notif"
                    onClick={() =>
                      RemoveNotification({
                        variables: { userId, type: 'verNotUser' },
                      })
                    }
                  >
                    <img src={removeNotIcon} alt="" />
                    Clear Notifications
                  </button>
                  <div className="nothing__found">
                    <h3>
                      <span>Employment Verfication</span>
                    </h3>
                    <p>Messaging and file transfers.</p>
                    {/* <button className="button"> <img src={mailImage} alt="" /> Send Notification</button> */}
                  </div>
                  <div className="ver__wrap">
                    <div className="columns is-gapless">
                      <div className="column is-8">
                        <Messenger
                          userId={adminId}
                          chatId={verChatId}
                          userName={adminName}
                        />
                      </div>
                      <div className="column is-4">
                        <Uploader
                          instanceId={verInstanceId}
                          userName={adminName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : // REFRENCES
            currentActive === 'references' ? (
              <>
                <div className="user__rundown__wrap">
                  {data.user.refInfo === '{}' ? (
                    <div className="nothing__found">
                      <h3>
                        No data found for <span>References</span>
                      </h3>
                      <p>
                        The user has provided no information for this section.
                      </p>
                      {/* <button className="button"> <img src={mailImage} alt="" /> Send Notification</button> */}
                    </div>
                  ) : (
                    <>
                      <button
                        className="button user__notif"
                        onClick={() =>
                          RemoveNotification({
                            variables: { userId, type: 'refNotUser' },
                          })
                        }
                      >
                        <img src={removeNotIcon} alt="" />
                        Clear Notifications
                      </button>
                      <RefrencesAdmin userId={userId} />
                    </>
                  )}
                  {/* <div className="info__del __alt">
                    <div className="del__wrap">
                        <button className="button __edit"> <img src={saveImage} alt="" /> Save changes</button>
                        <button className="button __delete"> <img src={rollbackImage} alt="" /> Rollback changes</button>
                    </div>
                </div> */}
                </div>
              </>
            ) : // RESUMES
            currentActive === 'resume' ? (
              <>
                <div className="user__rundown__wrap">
                  <button
                    className="button user__notif"
                    onClick={() =>
                      RemoveNotification({
                        variables: { userId, type: 'resNotUser' },
                      })
                    }
                  >
                    <img src={removeNotIcon} alt="" />
                    Clear Notifications
                  </button>
                  <div className="nothing__found">
                    <h3>
                      <span>Resumes</span>
                    </h3>
                    <p>Questions, Messaging and file transfers.</p>
                  </div>

                  <Mailer userId={userId} />

                  <ResumeAdmin userId={userId} />
                </div>
              </>
            ) : (
              // USER INFORMATION
              <>
                {popup === true ? (
                  <div className="edit__user__wrap">
                    <div className="edit__user">
                      <form onSubmit={handleFormSubmit}>
                        <div className="edit__user__top">
                          <h3>
                            Editing user <span>{data.user.name}</span>
                          </h3>
                          <div className="login__item field">
                            <p>Name</p>
                            <input
                              type="text"
                              className="input"
                              name="name"
                              onChange={handleOnChange}
                              placeholder="New name"
                              value={input.name}
                              required
                            />
                          </div>
                          <div className="login__item field">
                            <p>Gender</p>
                            <input
                              type="text"
                              className="input"
                              name="gender"
                              onChange={handleOnChange}
                              placeholder="Their Gender"
                              value={input.gender}
                              required
                            />
                          </div>
                          <div className="login__item field">
                            <p>Batch Number</p>
                            <input
                              type="text"
                              className="input"
                              name="batchNumber"
                              onChange={handleOnChange}
                              placeholder="Batch number"
                              value={input.batchNumber}
                              required
                            />
                          </div>
                          <div className="login__item field">
                            <p>Email</p>
                            <input
                              type="text"
                              className="input"
                              name="email"
                              onChange={handleOnChange}
                              placeholder="New email"
                              value={input.email}
                              required
                            />
                          </div>
                          <div className="login__item field">
                            <p>Phone</p>
                            <input
                              type="text"
                              className="input"
                              name="phone"
                              onChange={handleOnChange}
                              placeholder="New phone"
                              value={input.phone}
                              required
                            />
                          </div>
                        </div>
                        <div className="edit__user__bottom">
                          <button className="button" type="submit">
                            <img src={saveImage} alt="" /> Save
                          </button>
                          <button
                            className="button"
                            type="button"
                            onClick={() => handleCancel()}
                          >
                            <img src={rollbackImage} alt="" /> Close
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="patient__info">
                  <div className="info__col">
                    <div className="info__item">
                      <h5>Full name</h5>
                      <h3>{data.user.name}</h3>
                    </div>
                  </div>
                  <div className="info__col">
                    <div className="info__item">
                      <h5>Email address</h5>
                      <h4>{data.user.email}</h4>
                    </div>
                    <div className="info__item">
                      <h5>Phone number</h5>
                      <h4>{data.user.phone}</h4>
                    </div>
                  </div>
                  <div className="info__col">
                    <div className="info__item">
                      <h5>Gender</h5>
                      <h4>{data.user.gender}</h4>
                    </div>
                    <div className="info__item">
                      <h5>Batch Number</h5>
                      <h4>{data.user.batchNumber}</h4>
                    </div>
                  </div>
                  <div className="info__col">
                    <div className="info__item">
                      <h5>Last Active</h5>
                      <h4>{data.user.lastLogin}</h4>
                    </div>
                  </div>
                  {/* <div className="info__col">
                            <div className="info__item">
                                <h5>Resume Provided</h5>
                                <h4>{data.user.resInfo === "{}" ? 'False' : 'True'}</h4>
                            </div>
                            <div className="info__item">
                                <h5>References Provided</h5>
                                <h4>{data.user.refInfo === "{}" ? 'False' : 'True'}</h4>
                            </div>
                            <div className="info__item">
                                <h5>Verification Provided</h5>
                                <h4>{data.user.verInfo === "{}" ? 'False' : 'True'}</h4>
                            </div>
                        </div> */}
                </div>

                <div className="info__del">
                  <div className="del__wrap">
                    <button
                      className="button __edit"
                      onClick={() => {
                        setInput(initialState);
                        setPopup(true);
                      }}
                    >
                      {' '}
                      <img src={editImage} alt="" /> Edit user
                    </button>
                    <button
                      className="button __delete"
                      onClick={() => handleDelete()}
                    >
                      {' '}
                      <img src={deleteImage} alt="" /> Delete user
                    </button>
                  </div>
                </div>
              </>
            )
          }
        </div>
      </div>
    </>
  );
}
