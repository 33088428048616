import React from 'react';
import './styles/globals.scss';
import { render } from "react-dom";
import {
  BrowserRouter,
} from "react-router-dom";
import App from "./App";
import { ApolloProvider } from '@apollo/client'
import client from './Components/ApolloClient'
import { AuthProvider } from './Context/authContext';
import { NotifContextProvider} from './Context/notifContext';

const rootElement = document.getElementById("root");
render(
  <AuthProvider>
    <NotifContextProvider>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
    </NotifContextProvider>
  </AuthProvider>,
  rootElement
);