import { ApolloClient, InMemoryCache, createHttpLink, split, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { createUploadLink } from "apollo-upload-client";

const uploadLink = createUploadLink({ 
  uri: "https://appbacktest.jobs93.com/graphql" });
const httpLink = createHttpLink({
  uri: 'https://appbacktest.jobs93.com/graphql'
})

const wsLink = new GraphQLWsLink(createClient({
  url: 'wss://appbacktest.jobs93.com/subscriptions',
}));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  uploadLink,
);

const authLink = setContext((_, { header }) => {
  return {
    headers: {
      authorization: localStorage.getItem("token") || ""
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors',
  },
});

export default client;