import React, { useState, useEffect, useContext } from 'react';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Landing from "./routes/landing";
import Login from "./routes/login";
import Register from "./routes/register";
import Dashboard from './routes/dashboard';
import Users from './routes/users';
import User from './routes/user';
import Resume from './routes/resume';
import References from './routes/refrences';
import Verification from './routes/verification';
import Questions from './routes/question';
import { AuthContext } from "./Context/authContext";
import { NotifContext } from "./Context/notifContext";
import Messaging from './routes/messaging';
import Account from './routes/account';

function App() {

  const {user} = useContext(AuthContext)
  const {handleNotification} = useContext(NotifContext)

  function handler(x){
    if(x !== null){
      return true
    } else{
      return false
    }
  }

  // useEffect(() => {

  //   const temp = localStorage.getItem("token")
  //   handleLogged(temp)

  // }, [])

  // console.log(user)

  return (
    <>
    {
      handleNotification === true ?
      <div className="main__saving">
        <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMGMtNi42MjcgMC0xMiA1LjM3My0xMiAxMnM1LjM3MyAxMiAxMiAxMiAxMi01LjM3MyAxMi0xMi01LjM3My0xMi0xMi0xMnptLTEgMTdsLTUtNS4yOTkgMS4zOTktMS40MyAzLjU3NCAzLjczNiA2LjU3Mi03LjAwNyAxLjQ1NSAxLjQwMy04IDguNTk3eiIvPjwvc3ZnPg==" alt="" />
        <p>The changes have been saved</p>
      </div>
      : handleNotification === false ?
      <div className="main__saving __fail">
        <img src="data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTIuMDAyIDIuMDA1YzUuNTE4IDAgOS45OTggNC40OCA5Ljk5OCA5Ljk5NyAwIDUuNTE4LTQuNDggOS45OTgtOS45OTggOS45OTgtNS41MTcgMC05Ljk5Ny00LjQ4LTkuOTk3LTkuOTk4IDAtNS41MTcgNC40OC05Ljk5NyA5Ljk5Ny05Ljk5N3ptMCA4LjkzMy0yLjcyMS0yLjcyMmMtLjE0Ni0uMTQ2LS4zMzktLjIxOS0uNTMxLS4yMTktLjQwNCAwLS43NS4zMjQtLjc1Ljc0OSAwIC4xOTMuMDczLjM4NC4yMTkuNTMxbDIuNzIyIDIuNzIyLTIuNzI4IDIuNzI4Yy0uMTQ3LjE0Ny0uMjIuMzQtLjIyLjUzMSAwIC40MjcuMzUuNzUuNzUxLjc1LjE5MiAwIC4zODQtLjA3My41My0uMjE5bDIuNzI4LTIuNzI4IDIuNzI5IDIuNzI4Yy4xNDYuMTQ2LjMzOC4yMTkuNTMuMjE5LjQwMSAwIC43NS0uMzIzLjc1LS43NSAwLS4xOTEtLjA3My0uMzg0LS4yMi0uNTMxbC0yLjcyNy0yLjcyOCAyLjcxNy0yLjcxN2MuMTQ2LS4xNDcuMjE5LS4zMzguMjE5LS41MzEgMC0uNDI1LS4zNDYtLjc1LS43NS0uNzUtLjE5MiAwLS4zODUuMDczLS41MzEuMjJ6IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=" alt="" />
        <p>Sorry, Something went wrong</p>
      </div>
      : ""
    }
    
    <Routes>
      <Route path="/" element={<Landing/>} />
      <Route path="/login" element={handler(user) === true ? <Navigate to="/dashboard" /> : <Login />}/>
      <Route path="/register" element={handler(user) === true ? <Navigate to="/dashboard" /> : <Register />} />
      <Route path="/dashboard" element={handler(user) === false ? <Navigate to="/login" /> : <Dashboard />} />
      <Route path="/my-account" element={handler(user) === false ? <Navigate to="/login" /> : <Account />} />
      <Route path="/references" element={handler(user) === false ? <Navigate to="/login" /> : <References />} />
      <Route path="/messaging" element={handler(user) === false ? <Navigate to="/login" /> : <Messaging />} />
      <Route path="/question" element={handler(user) === false ? <Navigate to="/login" /> : <Questions /> } />
      <Route path="/verification" element={handler(user) === false ? <Navigate to="/login" /> : <Verification />} />
      <Route path="/resume-builder" element={handler(user) === false ? <Navigate to="/login" /> : <Resume />} />
      <Route path="/users" element={handler(user) === false ? <Navigate to="/login" /> : <Users />} />
      <Route path="/users/:id" element={handler(user) === false ? <Navigate to="/login" /> : <User />} />
      
    </Routes>
    </>
    // : ""
  );
}

export default App;
