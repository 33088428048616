import React, { createContext, useState } from 'react'

const NotifContext = createContext();

function NotifContextProvider(props) {

    const [ handleNotification, setHandleNotification ] = useState( null );

    function handleNotificationSuccess(x) {
        if(x === true){
            setHandleNotification(true)
        } else {
            setHandleNotification(false)
        }
    }

    if(handleNotification !== null){
        setTimeout(function () {
            setHandleNotification(null)
        }, 5000);
    }

    return(
        <NotifContext.Provider 
            value={{ handleNotification, handleNotificationSuccess }}
            {...props}
        />
    )
}



export { NotifContext, NotifContextProvider};