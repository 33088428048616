import React,{ useEffect} from 'react';
import navIconLight from "./../images/icon-light-bg.svg"
import navIconDark from "./../images/icon-dark-bg.svg"


export default function NavLanding() {

  const [scrolled,setScrolled]=React.useState(false);
  const handleScroll=() =>{
    const offset=window.scrollY;
    if(offset > 75 ){
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  let navbarClasses=["nav__main"];

  if(scrolled){
    navbarClasses.push("__stick");
  }

  return (
    <nav className={navbarClasses.join(" ")}>
      <div className="nav__container">

        <div className="nav__left">
          {
            navbarClasses.length > 1 ? 
            <img src={navIconDark} alt="" className="" />
            :
            <img src={navIconLight} alt="" className="" />
          }
          <div className="nav__items">
            <a href="/">Home</a>
            {/* <a href="/">Our Functionality</a>
            <a href="/">What we do</a>
            <a href="/">Reviews & Feedback</a>
            <a href="/">Contact us</a> */}
          </div>
        </div>

        <div className="nav__right">
         <a href="/login"><button className="button">Login</button></a>
         <a href="/register"><button className="button __alt">Sign Up</button></a>
        </div>

      </div>
    </nav>
  )
}
