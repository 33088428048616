import React, { useContext } from 'react'
import { useState } from 'react';
import searchIcon from "./../images/search.svg"
import bellIcon from "./../images/bell-icon.svg"
import gearIcon from "./../images/gear.svg"
import logoutIcon from "./../images/logout.svg"
import userIcon from "./../images/portrait_placeholder.png"
import chevronIcon from "./../images/chevron-down.svg"
import chevronRightIcon from "./../images/chev-right.svg"
import { AuthContext } from "../Context/authContext";


export default function NavTop(props) {

    const {user, logout} = useContext(AuthContext)

    // console.log(user)

    const current = props.current

    const userName = props.user

    const { search, setSearch ,searchNumber } = props;

    // const [search, setSearch] = useState("")

    function handleLogout() {
        logout()
        localStorage.removeItem("token")
        window.location.reload(false);
    }
    

  return (
    <nav className="nav__top">
    <div className="nav__top__wrap">
        
        <div className="nav__icon__left">
            
            {/* SEARCH */}
            {
                current === "pat" ?
                <div className={search !== "" ? "nav__search __active" : "nav__search"}>
                    <img src={searchIcon} alt="" />
                    <input type="text" className="input" placeholder="Search" value={search} onChange={ e => setSearch(e.target.value)}/>
                    {
                        search !== "" ?
                        <div className="search__results">
                            <p>{searchNumber} results for found for "<span>{search}</span>"</p>
                            {/* <div className="__warning">
                                <p>Search functionality is still <span>WIP</span></p>
                            </div> */}
                        </div>
                        : ""
                    }
                </div>
                : ""
            }

            {/* BREADCRUMBS */}
            {
                current !== "pat" ?
                    current === "dash" ?
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p className="__active">Dashboard</p></a>
                    </div>
                    : current === "ref" ?
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p>Dashboard</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/references"><p className="__active">References</p></a>
                    </div>
                    : current === "acc" ?
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p>Dashboard</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/my-account"><p className="__active">My Account</p></a>
                    </div>
                    : current === "mess" ?
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p>Dashboard</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/messaging"><p className="__active">Support</p></a>
                    </div>
                    : current === "ver" ?
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p>Dashboard</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/verification"><p className="__active">Verification</p></a>
                    </div>
                    : current === "que" ?
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p>Dashboard</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/question"><p className="__active">Statistics</p></a>
                    </div>
                    : current === "res" ? 
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p>Dashboard</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/resume-builder"><p className="__active">Resume Builder</p></a>
                    </div>
                    : current === "res-1" ? 
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p>Dashboard</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/resume-builder"><p>Resume Builder</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/resume-builder/resume-1"><p className="__active">Resume 1</p></a>
                    </div>
                    : current === "pat-fe" ?
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p>Dashboard</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/users"><p>Users</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/users/001"><p className="__active">{userName}</p></a>
                    </div>
                    : current === "ana" ?
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p>Dashboard</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/analytics"><p className="__active">Analytics</p></a>
                    </div>
                    : current === "info" ?
                    <div className="nav__breadcrumbs">
                        <a href="/dashboard"><p>Dashboard</p></a>
                        <img src={chevronRightIcon} alt="" />
                        <a href="/information"><p className="__active">Information</p></a>
                    </div>
                    :""
                :""
            }
            
        </div>

        <div className="nav__user__right">
            <div className="__placeholder"></div>
            <button className="button button__navigation">
                <img src={bellIcon} alt="" />
                {/* <div className="notification__new"></div> */}
                <div className="notification__pop">
                    <div className="notification__pop__wrap ">
                    {/* has__notification */}
                        <p>No new <br/>notifications</p>
                        {/* {
                            user.type === "admin" ?
                            <div className="not__item">
                                TestF TestL has updated <br/> their refrences
                                <button className="button">x</button>
                            </div>
                            :
                            <div className="not__item">
                            Your references has been <br/>reviewed and updated to <br/>step 3
                            <div className="button__close">x</div>
                        </div>
                        } */}
                        
                    </div>
                    
                </div>
            </button>
            <div className="side__divider"></div>
            <div className="user__wrap">
                <button className="button button__user">
                    <img src={userIcon} alt="" className="button__icon"/>
                    <p>{user.name}</p>
                    <img src={chevronIcon} alt="" className="button__chev" />
                </button>
                <div className="user__bottom">
                    <a href="/my-account"> <img src={gearIcon} alt="" /> My Account</a>
                    <a href="/" onClick={()=> handleLogout()}> <img src={logoutIcon} alt=""/> Logout</a>
                </div>
            </div>
            
        </div>
    </div>
    </nav>
  )
}
