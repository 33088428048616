import React, { useContext } from 'react'
import NavSide from '../Components/NavSide'
import NavTop from '../Components/NavTop'
import patientIcon from "./../images/patient.svg"
import analyticsIcon from "./../images/analytics.svg"
import infoIcon from "./../images/info.svg"
import helpIcon from "./../images/help-icon.svg"
import userIcon from "./../images/user-icon.svg"
import { AuthContext } from "../Context/authContext";
import { gql, useQuery, useMutation } from '@apollo/client';

const HOME_QUERY = gql`
  query GetUsers($userId: String!, $instanceId: String!,$userId2: ID!){
    users {
      name
      id
      lastLogin
      type
    }
    resumes {
        id
        userId
        fullName
        batchNumber
    }
    references {
        id
        step
    }
    mailsAll {
        id
        subject
        date
    }
    reference(userId: $userId, ) {
        step
        id
        dateCreated
    }
    files(instanceId: $instanceId) {
        id
        date
    }
    mails(userId: $userId2) {
        id
        date
        userName
    }
    resume(userId: $userId) {
        id
        batchNumber
    }
  }
`

export default function Dashboard() {

    const {user, logout} = useContext(AuthContext)
    
    const userId = user.id.toString()
    const instanceId = user.id.toString() + "ver"
    const params = {
        userId: userId,
        instanceId: instanceId,
        userId2: userId
    }

    const { loading, error, data } = useQuery(HOME_QUERY, {
        variables: params,
    });

    function getDate(time) {
        const date = new Date(time);
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        const currentDate = mm + '/' + dd + '/' + yyyy;
        const hourCurrent = date.getHours() > 12 ? (date.getHours() - 12).toString() : (date.getHours()).toString();
        const minuteCurrent = ('0'+date.getMinutes()).slice(-2);
        const designation = date.getHours() > 12 ? "pm" : "am";
        const timeCurrent = hourCurrent  + ":" + minuteCurrent + designation;

        return currentDate
    }

    function getTime(time) {
        const date = new Date(time);
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        const currentDate = mm + '/' + dd + '/' + yyyy;
        const hourCurrent = date.getHours() > 12 ? (date.getHours() - 12).toString() : (date.getHours()).toString();
        const minuteCurrent = ('0'+date.getMinutes()).slice(-2);
        const designation = date.getHours() > 12 ? "pm" : "am";
        const timeCurrent = hourCurrent  + ":" + minuteCurrent + designation;

        return timeCurrent
    }

    function perRef(dataM){
        const tempArray = [];
        if (dataM.references.length){
            for(let i = 0; i < dataM.references.length; i++){
                if(dataM.references[i].step === '2'){
                    tempArray.push(true)
                }
            }
        }
        return Math.abs(Math.trunc((100 * tempArray.length) / dataM.references.length) - 100);
    }

    function perRefSingle(dataM){
        const tempArray = [];
        if (dataM.reference.length){
            for(let i = 0; i < dataM.reference.length; i++){
                if(dataM.reference[i].step === '2'){
                    tempArray.push(true)
                }
            }
        }
        return tempArray.length
    }

    function perRes(dataM){
        return Math.abs(Math.trunc((100 * dataM.resumes.length) / dataM.users.length) - 100);
    }

    function myEmails(dataM){
        const tempArray = [];
        if (dataM.mails.length){
            for(let i = 0; i < dataM.mails.length; i++){
                if(dataM.mails[i].userName === user.name){
                    tempArray.push(true)
                }
            }
        }
        return tempArray.length
    }

    if(loading === true){
        return <></>
    }

  return (
    <>
        <NavTop current="dash"/>
        <NavSide current="dash"/>
        <div className="main__section">
            <div className="main__container">
                <div className="title__wrap">
                    <h2>Application Overview</h2>
                    <h1>Your Dashboard</h1>
                </div>
                { user.type === "user" ?
                <>
                    <div className="content__wrap">
                        <p>An overview of the application including summaries of the other applications parts, resume builder, references etc.</p>
                    </div>
                    {/* <p className="__wip">
                        This is currently a <span>placeholder</span>
                    </p> */}
                    <div className="dash__section">
                        <h4> <img src={patientIcon} alt="" /> Resume Builder</h4>
                        <div className="__border"></div>
                        <div className="dash__items__wrap">
                            <div className="dash__item">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOC4wMTIgMTUuODc2djQuMTI0bDEuNzM1LTIuNTc4LTEuNzM1LTEuNTQ2em0tNC4wMjYgNS44NzFjLS42NDUuNDA1LTEuMzExLjc2NS0xLjk4NiAxLjA2OWwuNDkyIDEuMTg0Yy42NzUtLjMwMyAxLjM0My0uNjU4IDEuOTkyLTEuMDU2bC0uNDk4LTEuMTk3em0zLjAxNC0yLjQwN2MtLjU5LjU4MS0xLjI1MyAxLjE3MS0xLjkzMiAxLjY3bC41MDUgMS4yMTRjLjQ4Ny0uMzQ2Ljk3Ny0uNzU4IDEuNDI3LTEuMTQ2di0xLjczOHptOS0xMy4zNGwtMy4xOTUgMTIuNzE2LTQuMzI5LTMuODU1IDQuMTU0LTQuMzg1LTUuNTY4IDMuODQ5LTMuODQzLS45MzQgMTIuNzgxLTcuMzkxem0tMiAuMDAxbC0uOTQ0LjU0NmMtLjAzNC0uMTc4LS4wNTYtLjM1OS0uMDU2LS41NDcgMC0xLjY1NCAxLjM0Ni0zIDMtM3MzIDEuMzQ2IDMgM2MwIDEuNTU3LTEuMTk2IDIuODI2LTIuNzE2IDIuOTcxbC4yNjYtMS4wNThjLjgzNS0uMjQgMS40NS0xLjAwMSAxLjQ1LTEuOTEzIDAtMS4xMDQtLjg5Ni0yLTItMnMtMiAuODk2LTIgMi4wMDF6bTgtLjAwMWMwIDMuMzEzLTIuNjg3IDYtNiA2bC0uNDcxLS4wMjQuNDk3LTEuOTc5YzIuMTk0LS4wMTUgMy45NzQtMS44MDEgMy45NzQtMy45OTcgMC0yLjIwNi0xLjc5NC00LTQtNHMtNCAxLjc5NC00IDRjMCAuMzcxLjA2Ny43MjMuMTYyIDEuMDY0bC0xLjc3OSAxLjAyOWMtLjI0My0uNjUzLS4zODMtMS4zNTYtLjM4My0yLjA5MyAwLTMuMzEzIDIuNjg3LTYgNi02czYgMi42ODcgNiA2eiIvPjwvc3ZnPg==" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                {
                                    data.mails.length ?
                                    <>
                                        <h5>Last mail at <span>{getDate(data.mails[data.mails.length -1].date)}</span> </h5>
                                        <p>In total you have <span>{data.mails.length}</span> items in your inbox, of which <span>{myEmails(data)}</span> were sent by you!</p>
                                    </>
                                    :
                                    <>
                                        <h5><span>0</span> sent mail items</h5>
                                        <p>You have not sent any mail items in Jobs93, to get started with mails click <a href="/resume-builder">here</a></p>
                                    </>
                                }
                                
                            </div>

                            <div className="dash__item">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMiA0djE2LjAwMmMwIC4zODUuMjIuNzM1LjU2Ny45MDIuMzQ2LjE2Ni43NTguMTE5IDEuMDU4LS4xMjFsNC43MjUtMy43ODFoMTIuNjVjLjU1MiAwIDEtLjQ0OCAxLTF2LTEyLjAwMmMwLS41NTItLjQ0OC0xLTEtMWgtMThjLS41NTIgMC0xIC40NDgtMSAxem05Ljk5OCA1LjAwMmMuNDE0IDAgLjc1LjMzNi43NS43NXYzLjVjMCAuNDE0LS4zMzYuNzUtLjc1Ljc1cy0uNzUtLjMzNi0uNzUtLjc1di0zLjVjMC0uNDE0LjMzNi0uNzUuNzUtLjc1em0uMDAyLTNjLjU1MiAwIDEgLjQ0OCAxIDFzLS40NDggMS0xIDEtMS0uNDQ4LTEtMSAuNDQ4LTEgMS0xeiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                {
                                    data.resume.length ?
                                    <>
                                        <h5>Questions <span>have</span> been answered</h5>
                                        <p><span>Thank you</span> for answering the questions provided in the resume builder section.</p>
                                    </>
                                    :
                                    <>
                                        <h5>Questions <span>haven't</span> been answered</h5>
                                        <p>Please click <a href="/resume-builder">here</a> to answer the questions provided in the resume section.</p>
                                    </>
                                }
                            </div>
                        </div>
                        
                    </div>

                    <div className="dash__section">
                        <h4> <img src={infoIcon} alt="" /> References</h4>
                        <div className="__border"></div>
                        <div className="dash__items__wrap">
                            <div className="dash__item ref__one">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTAuNSAxNy4yNWMwLS40MTQuMzM2LS43NS43NS0uNzVoMTBjLjQxNCAwIC43NS4zMzYuNzUuNzVzLS4zMzYuNzUtLjc1Ljc1aC0xMGMtLjQxNCAwLS43NS0uMzM2LS43NS0uNzV6bS0xLjUtMy41NWMwLS41My0uNDctMS0xLTFoLTVjLS41MyAwLTEgLjQ3LTEgMXY0LjNjMCAuNTMuNDcgMSAxIDFoNWMuNTMgMCAxLS40NyAxLTF6bTEuNS0xLjdjMC0uNDE0LjMzNi0uNzUuNzUtLjc1aDEwYy40MTQgMCAuNzUuMzM2Ljc1Ljc1cy0uMzM2Ljc1LS43NS43NWgtMTBjLS40MTQgMC0uNzUtLjMzNi0uNzUtLjc1em0tMS41LTZjMC0uNTMtLjQ3LTEtMS0xaC01Yy0uNTMgMC0xIC40Ny0xIDF2NC4zYzAgLjUzLjQ3IDEgMSAxaDVjLjUzIDAgMS0uNDcgMS0xem0xLjUuNzVjMC0uNDE0LjMzNi0uNzUuNzUtLjc1aDEwYy40MTQgMCAuNzUuMzM2Ljc1Ljc1cy0uMzM2Ljc1LS43NS43NWgtMTBjLS40MTQgMC0uNzUtLjMzNi0uNzUtLjc1eiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                {
                                    data.reference.length ?
                                    <>
                                        <h5><span>{data.reference.length}</span> total references provided</h5>
                                        <p>Your last reference was made on <span>{data.reference[data.reference.length - 1].dateCreated}</span>, to add more please click <a href="/references">here</a></p>
                                    </>
                                    :
                                    <>
                                        <h5>References <span>haven't</span> been answered</h5>
                                        <p>Please click <a href="/references">here</a> to add references to your account.</p>
                                    </>
                                }
                            </div>

                            <div className="dash__item ref__two">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjAgMjBoLTE1LjI1Yy0uNDE0IDAtLjc1LjMzNi0uNzUuNzVzLjMzNi43NS43NS43NWgxNS43NWMuNTMgMCAxLS40NyAxLTF2LTE1Ljc1YzAtLjQxNC0uMzM2LS43NS0uNzUtLjc1cy0uNzUuMzM2LS43NS43NXptLTEtMTdjMC0uNDc4LS4zNzktMS0xLTFoLTE1Yy0uNjIgMC0xIC41MTktMSAxdjE1YzAgLjYyMS41MiAxIDEgMWgxNWMuNDc4IDAgMS0uMzc5IDEtMXptLTEyLjc1MSA4LjMwNmMtLjE2NS0uMTQ3LS4yNDktLjM1MS0uMjQ5LS41NTYgMC0uNDExLjMzMy0uNzQ2Ljc0OC0uNzQ2LjE3OCAwIC4zNTUuMDYyLjQ5OS4xOWwyLjM3MSAyLjAxMSA0LjQ1My00Ljk2MmMuMTQ5LS4xNjEuMzUtLjI0My41NTQtLjI0My40MTcgMCAuNzQ4LjMzNi43NDguNzQ2IDAgLjE3OS0uMDY1LjM1OS0uMTk2LjUwM2wtNC45NTMgNS41MDhjLS4xNDguMTYxLS4zNS4yNDMtLjU1My4yNDMtLjE3NyAwLS4zNTYtLjA2My0uNDk4LS4xOXoiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                {
                                    data.reference.length ?
                                    <>
                                        <h5><span>{perRefSingle(data)}</span> References set as completed</h5>
                                        <p>To view the changes, and information that was added to your reference, click <a href="/references">here</a>.</p>
                                    </>
                                    :
                                    <>
                                        <h5><span>0</span> references have been answered</h5>
                                        <p>Please click <a href="/references">here</a> to add references, or wait for admin feedback.</p>
                                    </>
                                }
                            </div>
                        </div>
                        
                    </div>

                    <div className="dash__section">
                        <h4> <img src={analyticsIcon} alt="" /> Employment verification</h4>
                        <div className="__border"></div>
                        <div className="dash__items__wrap">
                            <div className="dash__item ver__one">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQuNTY4LjA3NWMyLjIwMiAxLjE3NCA1LjkzOCA0Ljg4MyA3LjQzMiA2Ljg4MS0xLjI4Ni0uOS00LjA0NC0xLjY1Ny02LjA5MS0xLjE3OS4yMjItMS40NjgtLjE4NS00LjUzNC0xLjM0MS01LjcwMnptNy40MzIgMTAuOTI1djEzaC0yMHYtMjRoOC40MDljNC44NTcgMCAzLjMzNSA4IDMuMzM1IDggMy4wMDktLjc0NSA4LjI1Ni0uNDE5IDguMjU2IDN6bS0xNSAxLjVjMCAuODI4LjY3MiAxLjUgMS41MDEgMS41LjgyNyAwIDEuNDk5LS42NzIgMS40OTktMS41cy0uNjcyLTEuNS0xLjQ5OS0xLjVjLS44MjkgMC0xLjUwMS42NzItMS41MDEgMS41em0xMCA2LjVsLTMuNS02LTIuMDkzIDIuOTY4LTEuMzEtLjk2OC0zLjA5NyA0aDEweiIvPjwvc3ZnPg==" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                {
                                    data.files.length ?
                                    <>
                                        <h5><span>{data.files.length}</span> files shared</h5>
                                        <p>The last file upload was on <span>{data.files[data.files.length -1].date}</span>, to keep messenging click <a href="/verification">here</a></p>
                                    </>
                                    :
                                    <>
                                        <h5><span>0</span> files shared</h5>
                                        <p>There have been <span>no</span> files provided, to keep messenging click <a href="/verification">here</a></p>
                                    </>
                                }
                            </div>

                        </div>
                        
                    </div>

                </>
                :
                <>
                    <div className="content__wrap">
                        <p>An overview of the application focusing on what users have been doing, and data disclosing thier usage.</p>
                    </div>
                    {/* <p className="__wip">
                        This is currently a <span>placeholder</span>
                    </p> */}
                    <div className="dash__section">
                        <h4> <img src={helpIcon} alt="" /> Statistics</h4>
                        <div className="__border"></div>
                        <div className="dash__items__wrap">
                            <div className="dash__item ref__one">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNLjAwMiAyMGg2LjAwMWMtLjAyOC02LjU0MiAyLjk5NS0zLjY5NyAyLjk5NS04LjkwMSAwLTIuMDA5LTEuMzExLTMuMDk5LTIuOTk4LTMuMDk5LTIuNDkyIDAtNC4yMjYgMi4zODMtMS44NjYgNi44MzkuNzc1IDEuNDY0LS44MjUgMS44MTItMi41NDUgMi4yMDktMS40OS4zNDQtMS41ODkgMS4wNzItMS41ODkgMi4zMzNsLjAwMi42MTl6bTIwLjQ5OC03Yy0xLjkzMiAwLTMuNSAxLjU2Ny0zLjUgMy41czEuNTY4IDMuNSAzLjUgMy41IDMuNS0xLjU2NyAzLjUtMy41LTEuNTY4LTMuNS0zLjUtMy41em0xLjUgNGgtMXYxaC0xdi0xaC0xdi0xaDF2LTFoMXYxaDF2MXptLTQuODE0IDNoLTkuMTgzbC0uMDAzLS44MjljMC0xLjY3OS4xMzMtMi42NDkgMi4xMTgtMy4xMDcgMi4yNDMtLjUxOCA0LjQ1OC0uOTgxIDMuMzk0LTIuOTQ1LTMuMTU2LTUuODItLjkwMS05LjExOSAyLjQ4OC05LjExOSA0LjA2IDAgNC44NTcgNC4xMTkgMy4wODUgNy45MDMtMS45NzIuNjA5LTMuNDE5IDIuNDI4LTMuNDE5IDQuNTk3IDAgMS4zOC41ODkgMi42MTkgMS41MiAzLjV6Ii8+PC9zdmc+" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                <h5><span>{data.users.length}</span> new users this month</h5>
                                <p>There are currently a total of <span>{data.users.length}</span> user accounts, present in the application.</p>
                            </div>

                            <div className="dash__item ref__two">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xOC43OTkgNy4wMzhjLS40OTYtLjUzNS0uNzk5LTEuMjUyLS43OTktMi4wMzggMC0xLjY1NiAxLjM0NC0zIDMtM3MzIDEuMzQ0IDMgMy0xLjM0NCAzLTMgM2MtLjE0NiAwLS4yOS0uMDEtLjQzMS0uMDMxbC0zLjMzMyA2LjAzMmMuNDc1LjUzLjc2NCAxLjIzMS43NjQgMS45OTkgMCAxLjY1Ni0xLjM0NCAzLTMgM3MtMy0xLjM0NC0zLTNjMC0uNTgzLjE2Ny0xLjEyNy40NTUtMS41ODdsLTIuNTY1LTMuNTQ3Yy0uMjgxLjA4Ny0uNTguMTM0LS44OS4xMzRsLS4zNjgtLjAyMi0zLjM1NSA2LjA2OWMuNDUxLjUyNS43MjMgMS4yMDguNzIzIDEuOTUzIDAgMS42NTYtMS4zNDQgMy0zIDNzLTMtMS4zNDQtMy0zIDEuMzQ0LTMgMy0zYy4xODYgMCAuMzY3LjAxNy41NDMuMDQ5bDMuMjk4LTUuOTY3Yy0uNTItLjUzOS0uODQxLTEuMjczLS44NDEtMi4wODIgMC0xLjY1NiAxLjM0NC0zIDMtM3MzIDEuMzQ0IDMgM2MwIC42MTctLjE4NyAxLjE5MS0uNTA3IDEuNjY5bDIuNTI3IDMuNDk1Yy4zMDctLjEwNi42MzctLjE2NC45OC0uMTY0LjE2NCAwIC4zMjUuMDEzLjQ4Mi4wMzlsMy4zMTctNi4wMDF6bS0zLjc5OSA3Ljk2MmMuNTUyIDAgMSAuNDQ4IDEgMXMtLjQ0OCAxLTEgMS0xLS40NDgtMS0xIC40NDgtMSAxLTF6bS02LThjLjU1MiAwIDEgLjQ0OCAxIDFzLS40NDggMS0xIDEtMS0uNDQ4LTEtMSAuNDQ4LTEgMS0xeiIvPjwvc3ZnPg==" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                <h5><span>100%</span> of users are active</h5>
                                <p><span>100%</span> of users have logged into the application this month. <a href="/question">Learn more</a>.</p>
                            </div>
                        </div>
                        
                    </div>

                    <div className="dash__section">
                        <h4> <img src={userIcon} alt="" /> User Management</h4>
                        <div className="__border"></div>
                        <div className="dash__items__wrap">
                        <div className="dash__items__wrap">
                            <div className="dash__item ver__one __alt">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yNCAyM2gtMjR2LTEzLjI3NWwyLTEuNDU1di03LjI3aDIwdjcuMjcybDIgMS40NTN2MTMuMjc1em0tMjAtMTAuNDcydi05LjUyOGgxNnY5LjUyN2wtOCA1LjQ3My04LTUuNDcyem0xNC0uNTI4aC0xMnYtMWgxMnYxem0wLTN2MWgtMTJ2LTFoMTJ6bS03LTFoLTV2LTNoNXYzem03IDBoLTZ2LTFoNnYxem0wLTJoLTZ2LTFoNnYxeiIvPjwvc3ZnPg==" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                {
                                    data.mailsAll.length ? 
                                    <>
                                        <h5><span>{data.mailsAll.length}</span> total mail items</h5>
                                        <p>The last email was sent on <span>{getDate(data.mailsAll[data.mailsAll.length -1].date)}</span>, view more on emails <a href="/question">here</a>!</p>
                                    </>
                                    :
                                    <>
                                        <h5><span>0</span> total mail items</h5>
                                        <p>No emails have been sent on the system, view more on user activity <a href="/question">here</a>!</p>
                                    </>
                                }
                                
                                </div>

                            </div>
                            <div className="dash__item ">
                                
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjIgMTcuNzVjMC0uNDE0LS4zMzYtLjc1LS43NS0uNzVoLTEyLjVjLS40MTQgMC0uNzUuMzM2LS43NS43NXMuMzM2Ljc1Ljc1Ljc1aDEyLjVjLjQxNCAwIC43NS0uMzM2Ljc1LS43NXptLTE5LjgwNi0uNTM3IDEuMjQ5IDEuMTE0Yy4xMy4xMTYuMjkzLjE3My40NTYuMTczLjE4NCAwIC4zNjktLjA3NS41MDQtLjIyMmwyLjExNi0yLjMxM2MuMTE5LS4xMzEuMTc4LS4yOTYuMTc4LS40NTkgMC0uMzc1LS4zMDItLjY4Mi0uNjgzLS42ODItLjE4NSAwLS4zNjkuMDc0LS41MDQuMjIxbC0xLjY2MSAxLjgxNS0uNzQ2LS42NjVjLS4xMzEtLjExNi0uMjkyLS4xNzMtLjQ1NC0uMTczLS4zNzkgMC0uNjgzLjMwNy0uNjgzLjY4MiAwIC4xODguMDc2LjM3NC4yMjguNTA5em0xOS44MDYtMy40NjNjMC0uNDE0LS4zMzYtLjc1LS43NS0uNzVoLTEyLjVjLS40MTQgMC0uNzUuMzM2LS43NS43NXMuMzM2Ljc1Ljc1Ljc1aDEyLjVjLjQxNCAwIC43NS0uMzM2Ljc1LS43NXptLTE5LjgwNi0xLjQ0OSAxLjI0OSAxLjExNGMuMTMuMTE2LjI5My4xNzMuNDU2LjE3My4xODQgMCAuMzY5LS4wNzQuNTA0LS4yMjJsMi4xMTYtMi4zMTNjLjExOS0uMTMxLjE3OC0uMjk1LjE3OC0uNDU5IDAtLjM3NS0uMzAyLS42ODItLjY4My0uNjgyLS4xODUgMC0uMzY5LjA3NC0uNTA0LjIyMWwtMS42NjEgMS44MTUtLjc0Ni0uNjY1Yy0uMTMxLS4xMTYtLjI5Mi0uMTczLS40NTQtLjE3My0uMzc5IDAtLjY4My4zMDctLjY4My42ODMgMCAuMTg3LjA3Ni4zNzQuMjI4LjUwOHptMTkuODA2LTIuNTUxYzAtLjQxNC0uMzM2LS43NS0uNzUtLjc1aC0xMi41Yy0uNDE0IDAtLjc1LjMzNi0uNzUuNzVzLjMzNi43NS43NS43NWgxMi41Yy40MTQgMCAuNzUtLjMzNi43NS0uNzV6bS0xOS44MDYtMi4zNjEgMS4yNDkgMS4xMTRjLjEzLjExNi4yOTMuMTczLjQ1Ni4xNzMuMTg0IDAgLjM2OS0uMDc0LjUwNC0uMjIxbDIuMTE2LTIuMzEzYy4xMTktLjEzMS4xNzgtLjI5Ni4xNzgtLjQ2IDAtLjM3NC0uMzAyLS42ODItLjY4My0uNjgyLS4xODUgMC0uMzY5LjA3NC0uNTA0LjIyMWwtMS42NjEgMS44MTUtLjc0Ni0uNjY0Yy0uMTMxLS4xMTYtLjI5Mi0uMTczLS40NTQtLjE3My0uMzc5IDAtLjY4My4zMDYtLjY4My42ODIgMCAuMTg3LjA3Ni4zNzQuMjI4LjUwOXptMTkuODA2LTEuNjM5YzAtLjQxNC0uMzM2LS43NS0uNzUtLjc1aC0xMi41Yy0uNDE0IDAtLjc1LjMzNi0uNzUuNzVzLjMzNi43NS43NS43NWgxMi41Yy40MTQgMCAuNzUtLjMzNi43NS0uNzV6IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                <h5><span>{perRef(data)}%</span> awaiting Reference data</h5>
                                <p>Some users are awaiting to have resume data given to them click to <a href="/users">learn more</a></p>
                            </div>

                            <div className="dash__item ">
                                <img 
                                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNNS40OTUgMmgxNi41MDV2LTJoLTE3Yy0xLjY1NiAwLTMgMS4zNDMtMyAzdjE4YzAgMS42NTcgMS4zNDQgMyAzIDNoMTd2LTIwaC0xNi41MDVjLTEuMzc2IDAtMS4zNzYtMiAwLTJ6bTcuNTYxIDcuMjczYy40MzgtLjM3MiAxLjA4NC0uMzYzIDEuNDQ2LjAxOC4zNjEuMzgyLjMwMi45OTItLjEzNSAxLjM2NC0uNDM3LjM3Mi0xLjA4NC4zNjQtMS40NDYtLjAxOC0uMzYxLS4zODItLjMwMi0uOTkyLjEzNS0xLjM2NHptLjU4MyA0LjU2N2MtLjYyNyAxLjUwOC0xLjA3NSAyLjUyNS0xLjMzMSAzLjMxLS4zNzQgMS4xNDQuNTY5LjY4IDEuNDkzLS4xNzMuMTI3LjIwNi4xNjcuMjcxLjI5NC41MDgtMi4wNTQgMS45NTMtNC4zMzEgMi4xMjUtMy42MjMtLjEyLjQ2NC0xLjQ2OSAxLjM0Mi0zLjIyOSAxLjQ5Ni0zLjY3NS4yMjUtLjY0Ni0uMTc0LS45MzQtMS40MjkuMTcxbC0uMjc4LS41MjVjMS40MzEtMS41NTggNC4zODEtMS45MSAzLjM3OC41MDR6Ii8+PC9zdmc+" 
                                    alt="" 
                                    className="__overlay" 
                                />
                                <h5><span>{perRes(data)}%</span> are missing questions</h5>
                                {
                                    perRes(data) !== 0 ? 
                                    <p>There is a percentage of users missing thier Reference question answers.</p>
                                    :
                                    <p>Congratulations no users are currently missing thier Reference question answers.</p>
                                }
                                
                            </div>
                        </div>
                        
                    </div>

                </>
                }
                
            </div>
        </div>
        
    </>
  )
}
