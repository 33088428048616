import React, {useContext, useEffect} from 'react'
import NavSide from '../Components/NavSide'
import NavTop from '../Components/NavTop'
import { AuthContext } from "../Context/authContext";
import Messenger from '../Components/Messenger';
import Uploader from '../Components/Uploader';
import { useNavigate } from 'react-router';


export default function Verification() {

    const {user} = useContext(AuthContext)
    let navigate = useNavigate()

    useEffect(() => {
        if(user.type !== "user"){
            navigate('/dashboard')
        }
    }, [])
    

    

    const userId = user.id.toString()
    const chatId = user.id.toString() + "ver"
    const instanceId = user.id.toString() + "ver"
    const userName = user.name


  return (
    <>
        <NavTop current="ver"/>
        <NavSide current="ver"/>
        <div className="main__section">
            <div className="main__container">
                <div className="title__wrap">
                    <h2>Verify your experience</h2>
                    <h1>Employment Verification</h1>
                </div>
                <div className="content__wrap">
                    <p>The Employment Verification section. A chatroom and upload location to communicate directly with staff.</p>
                </div>
                <div className="ver__wrap">

                    <div className="columns is-gapless">
                        <div className="column is-8">
                            <Messenger userId={userId} chatId={chatId} userName={userName} />
                        </div>
                        <div className="column is-4">
                            <Uploader instanceId={instanceId} userName={userName}/>
                        </div>
                    </div>

                </div>


                
            </div>
        </div>
        
    </>
  )
}
